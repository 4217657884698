import React, { useEffect, useState } from 'react'
import rightArrow from '../assets/images/right-arrow.png'
import buttonArowWhite from '../assets/images/right-arrow1.png'
import Container from 'react-bootstrap/Container';
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import 'swiper/swiper.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/effect-fade/effect-fade.min.css';
// import 'swiper/modules/autoplay/autoplay.min.css';

import 'swiper/swiper-bundle.min.css';
import { Navigation, Autoplay } from "swiper";

/*
import RP1 from '../assets/images/rp-1.svg'
import RP2 from '../assets/images/rp-2.svg'
import RP3 from '../assets/images/rp-3.png'
import RP4 from '../assets/images/rp-4.png'
import { Link } from 'react-router-dom'
import rpBg from '../assets/images/rp-bg.png'
*/
import { GET_RELETED_SUBCATEGORY } from '../api/constant'

function RelatedProducts(props) {
    let subslug = props.subslug;
    let mainslug = props.mainslug;
    const [productData, setProductData] = useState([])
    const [errMsg, setErrMsg] = useState(null)
    useEffect(() => {

        const getRelatedSubCategories = async () => {
            const res = await fetch(GET_RELETED_SUBCATEGORY + mainslug + '/' + subslug)
            const data = await res.json()
            if (data.status) {
                setProductData(data.data)
            } else {
                setErrMsg(data.message)
            }
        }
        getRelatedSubCategories();
    }, []);
    return (
        <>{productData.length > 0}
            <div className='related-products-slider common-padding'> 
                <div className='common-title'>
                    <h2>Related Products <span className='common-grad'></span></h2>
                </div>
                <Swiper navigation autoplay={{
                    delay: 2500,
                }} modules={[Navigation, Autoplay]} breakpoints={{
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    576: {
                        slidesPerView: 2,
                        spaceBetween: 30
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 30
                    },
                    1199: {
                        slidesPerView: 4,
                        spaceBetween: 30,
                        slidesOffsetBefore: 0,
                    },
                    1599: {
                        slidesPerView: 4,
                        spaceBetween: 50,
                        slidesOffsetBefore: 0,
                    },
                }} spaceBetween={50} loop={true} slidesPerView={4} className="mySwiper">
                    {productData.length > 0 && productData.map((product) => {
                        return <SwiperSlide key={product.sub_category_id}>
                            <a href={"/plan-details/" + product.category_slug + "/" + product.sub_category_slug} className="related-products-box">
                                <img className='rp-bg' src={product.plan_feature_image} alt="" />
                                <img className='related-products-img' src={product.plan_icon_image} alt="" />
                                <img className='related-products-img img-active' src={product.plan_hover_icon_image} alt="" />
                                <h3>{product.sub_category_title}</h3>
                                {/* <div className='arrow' ><img src={buttonArowWhite} alt="" /></div> */}
                            </a>
                        </SwiperSlide>
                    })}

                </Swiper>
            </div>
        </>
    )
}

export default RelatedProducts