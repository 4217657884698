import React, { useEffect, useState, useCallback } from "react";
import { GET_LIST_JOB_POSTS } from "../api/constant";
import load from "../assets/images/load.png";
import { Accordion } from "react-bootstrap";
import { Button } from "react-bootstrap";
import Experience from "../assets/images/experience.svg";
import WorkingHours from "../assets/images/working-hours.svg";
import WorkingDays from "../assets/images/working-days.svg";
import Salary from "../assets/images/salary.svg";
import Vacancy from "../assets/images/vacancy.svg";
import Deadline from "../assets/images/deadline.svg";
import arrowDownDefault from "../assets/images/arrow-down-default.svg";
import arrowDownOnHover from "../assets/images/arrow-down-hover.svg";
import CareerForm from "../component/CareerForm";

const JobList = () => {
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [displayLoadBtn, setDisplayLoadBtn] = useState(false);
  const [jobPostItems, setJobPostItems] = useState([]);

  const getJobList = async (nextPage = 1) => {
    setLoading(true);
    setDisplayLoadBtn(false);
    try {
      const res = await fetch(`${GET_LIST_JOB_POSTS}?page=${nextPage}`);
      const data = await res.json();
      if (data.status && data.data.jobs) {
        const resultData = data.data;
        setJobPostItems((prevItems) => [...prevItems, ...resultData.jobs]);
        setPage(resultData.pagination.current);
        setTotalPage(resultData.pagination.numPages);
        if (resultData.pagination.numPages > resultData.pagination.current) {
          setDisplayLoadBtn(true);
        }
      } else {
        setErrMsg(data.message);
      }
    } catch (error) {
      setErrMsg("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getJobList();
  }, []);

  const loadMore = useCallback(() => {
    getJobList(page + 1);
  }, [page]);

  // Function to decode HTML entities
  const decodeHtml = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  const handleToggle = () => {
    localStorage.removeItem("firstForm");
    localStorage.removeItem("secondForm");
  };

 


  return (
    <>
      <Accordion data-total={totalPage}>
        {jobPostItems.map((row) => (
          <Accordion.Item key={row.id} eventKey={row.id.toString()}>
            <Accordion.Header onClick={() => handleToggle()}>
              <div className="opening-header">
                <div className="opening-text">
                  <h2>{row.job_title}</h2>
                  <div className="jld-group">
                    <p>
                      Job Type : <b>{row.job_type}</b>
                    </p>
                    <p>
                      Location : <b>{row.location}</b>
                    </p>
                    <p>
                      Date : <b>{row.created_date}</b>
                    </p>
                  </div>
                </div>
                <div className="opening-btn">
                  <p>
                    Jobs Details 
                    <img className="default" src={arrowDownDefault} alt="Arrow Down Default" />
                    <img className="on-hover" src={arrowDownOnHover} alt="Arrow Down on Hover" /> 
                  </p>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <div className="opening-body">
                <ul>
                  {row.experience && (
                    <li>
                      <img src={Experience} alt="Experience" />
                      <span>Experience</span>
                      <p>{row.experience} Years Experience</p>
                    </li>
                  )}
                  {row.working_hours && (
                    <li>
                      <img src={WorkingHours} alt="Working Hours" />
                      <span>Working Hours</span>
                      <p>{row.working_hours} Hours</p>
                    </li>
                  )}
                  {row.working_days && (
                    <li>
                      <img src={WorkingDays} alt="Working Days" />
                      <span>Working Days</span>
                      <p>{row.working_days} Days</p>
                    </li>
                  )}
                  {row.salary && (
                    <li>
                      <img src={Salary} alt="Salary" />
                      <span>Salary</span>
                      <p>{row.salary}</p>
                    </li>
                  )}
                  {row.no_vacancy && (
                    <li>
                      <img src={Vacancy} alt="Vacancy" />
                      <span>Vacancy</span>
                      <p>{row.no_vacancy} Vacancies</p>
                    </li>
                  )}
                  {row.deadline && (
                    <li>
                      <img src={Deadline} alt="Deadline" />
                      <span>Deadline</span>
                      <p>{row.deadline}</p>
                    </li>
                  )}
                </ul>
                <div className="job-description" dangerouslySetInnerHTML={{ __html: decodeHtml(row.job_description) }} />
                <div id="postOne">
                  <CareerForm job_id={row.id} job_title={row.job_title} />
                </div>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>

      {displayLoadBtn && (
        <div className="load-more mt-2">
          <Button disabled={loading} onClick={loadMore}>
            {loading ? (
              <>
                <img src={load} alt="Loading" />
                Loading...
              </>
            ) : (
              "LOAD MORE"
            )}
          </Button>
        </div>
      )}

      {errMsg && <div className="error-message">{errMsg}</div>}
    </>
  );
};

export default JobList;
