import React, { useEffect, useState } from 'react'
import Header from '../component/Header'
import { Col, Container, Row } from 'react-bootstrap'
import ArticleList from '../component/ArticleList'
import ProductCategories from '../component/ProductCategories'
import SimilarBlog from '../component/SimilarBlog'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer'
import subBanner from '../assets/images/sub-banner.png'
function FindForm() {
  const [errMsg, setErrMsg] = useState(null)
  const [setingData, setSetingData] = useState([])
  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();
  }, []);
  return (
    <>
      <Header setingData={setingData} />
      <div className='sub-banner'>
        <img src={subBanner} alt="" />
        <h1>Find Form</h1>
      </div>
      <div className='common-content-main'>
          <Row>
            <Col md={12}>
              <p>We are a federal contractor or subcontractor. The law requires us to provide equal employment opportunity to qualified people with disabilities. We have a goal of having at least 7% of our workers as people with disabilities. The law says we must measure our progress towards this goal. To do this, we must ask applicants and employees if they have a disability or have ever had one. People can become disabled, so we need to ask this question at least every five years.</p>
              <p>Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you want to learn more about the law or this form, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp. How do you know if you have a disability</p>
              <p>We are a federal contractor or subcontractor. The law requires us to provide equal employment opportunity to qualified people with disabilities. We have a goal of having at least 7% of our workers as people with disabilities. The law says we must measure our progress towards this goal. To do this, we must ask applicants and employees if they have a disability or have ever had one. People can become disabled, so we need to ask this question at least every five years.</p>
              <p>Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you want to learn more about the law or this form, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp. How do you know if you have a disability</p>
              <p>We are a federal contractor or subcontractor. The law requires us to provide equal employment opportunity to qualified people with disabilities. We have a goal of having at least 7% of our workers as people with disabilities. The law says we must measure our progress towards this goal. To do this, we must ask applicants and employees if they have a disability or have ever had one. People can become disabled, so we need to ask this question at least every five years.</p>
              <p>Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you want to learn more about the law or this form, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp. How do you know if you have a disability</p>
              <ul className="opening-list">
                <li>Demonstrate a minimum of ten (10) years’ of experience as a Subject Matter Expert in Data Analysis and Back End Development</li>
                <li>Have the ability to work independently with minimal oversight, innovative, a self-starter, flexible, articulate, and an effective communicator - both written and orally, providing thorough and completed projects that require only minor revisions and editing.</li>
                <li>Have at least ten (10) years’ experience supporting office and business process automation using a variety of business productivity tools such as O365 or similar to collate data and streamline processes.</li>
                <li>Have at least three (3) years’ experience using JavaScript, Python, JSON/ JSONP, REST calls, and SQL for the modification and manipulation of new or existing APIs to support integration with new and existing capabilities.</li>
                <li>Have at least ten (10) years’ experience in using web design and available development tools such as Notepad++, SharePoint Designer or related design tool with working knowledge of Visual Studio, a plus.</li>
                <li>A minimum three (3) years’ experience creating customized workflows and web-based forms to include experience improving business processes and automating the collection, organization, dissemination and archival of intelligence products using XSLT, XSL-FO, Java, JavaScript, Perl, Python, shell scripting, and/or node.js.</li>
                <li>A minimum three (3) years’ experience with data security management on DoD classified networks with both client-side and server side applications.</li>
                <li>Ability to use authentication security features and protocols when creating and/or modifying new and existing projects.</li>
                <li>Ability of creation, maintenance, updating and trouble-shooting of applications in a cloud-based environment, Security+ certification is highly desired. These skills and insight will guide and implement innovative advances with cloud computing services for the J2 organization.</li>
                <li>A minimum of three (3) years’ experience integrating data from two (2) or more data sources into a single location on dashboards with integrated, filterable, and searchable features to access data.</li>
                <li>Have at least three (3) years’ experience conducting customer surveys to gather feedback and requirements in order to improve processes and applications to increase information sharing and collaboration among geographically separated organizations.</li>
                <li>Have at least three (3) years’ with analysis, problem solving, and the ability to troubleshoot technical issues associated with individual web development efforts, web page rendering, latency and authentication/access issues.</li>
                <li>A minimum of three (3) years’ experience performing administrative duties in a joint environment.</li>
                <li>Have experience experience with Microsoft Office applications to include Word, PowerPoint, Outlook, Excel, and SharePoint 2019 / M365 or later, as well as experience or training administering web services for information management and knowledge management functions.</li>
                <li>A minimum of three (3) years’ experience providing training and educating customer base on existing, newly established, and implemented applications, to include documentation such as training materials, Standard Operating Procedures (SOPs), and flowcharts.</li>
                <li>At least three (3) years’ experience using technology to streamline unique business processes for the overall benefit of an organization. Demonstrated experience with Information Management (IM) / KM intelligence tools, processes, and activities is highly desired.</li> 
              </ul>
              <p>We are a federal contractor or subcontractor. The law requires us to provide equal employment opportunity to qualified people with disabilities. We have a goal of having at least 7% of our workers as people with disabilities. The law says we must measure our progress towards this goal. To do this, we must ask applicants and employees if they have a disability or have ever had one. People can become disabled, so we need to ask this question at least every five years.</p>
              <p>Completing this form is voluntary, and we hope that you will choose to do so. Your answer is confidential. No one who makes hiring decisions will see it. Your decision to complete the form and your answer will not harm you in any way. If you want to learn more about the law or this form, visit the U.S. Department of Labor’s Office of Federal Contract Compliance Programs (OFCCP) website at www.dol.gov/ofccp. How do you know if you have a disability</p>
            </Col>            
          </Row>
      </div>
      <Footer setingData={setingData} />
    </>
  )
}

export default FindForm