import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import { Routes, Route, useParams } from 'react-router-dom';
import { Col, Container, Row } from "react-bootstrap";
import { GET_SETTING_DATA, GET_PAGE_DATA } from "../api/constant";
import Footer from "../component/Footer";
import subBanner from "../assets/images/sub-banner.png";

function DynamicPage() {
  let { slug } = useParams();
  const [errMsg, setErrMsg] = useState(null);
  const [setingData, setSetingData] = useState([]);
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA);
      const data = await res.json();
      if (data.status) {
        setSetingData(data.data);
      } else {
        setErrMsg(data.message);
      }
    };

    const getPageData = async () => {
        const res = await fetch(GET_PAGE_DATA + slug)
        const data = await res.json();
        if (data.status) {
            setPageData(data.data);
        } else {
            setErrMsg(data.message);
        }
    };

    getPageData();
    getSettingData();
  }, []);
  return (
    <>
      <Header setingData={setingData} />
      <div className="sub-banner">
        <img src={subBanner} alt="" />
        <h1>{pageData[0]?.page_title}</h1>
      </div>
      <div className="common-content-main">
        <Row>
          <Col md={12}>
            <div dangerouslySetInnerHTML={{ __html: pageData[0]?.description }} />
          </Col>
        </Row>
      </div>
      <Footer setingData={setingData} />
    </>
  );
}

export default DynamicPage;
