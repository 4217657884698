import React, { useEffect, useState } from 'react'
import Logo from '../assets/images/INSUREGORA-LOGO.png'
import sideImg from '../assets/images/hero-banner-bg.png'
import bannerbggrad from '../assets/images/banner-bg-grad.png'
import bannervector from '../assets/images/banner-vector-img.png'
import callicon from '../assets/images/call-icon.png'
import scrolldownimg from '../assets/images/scroll-down-img.png'
import { EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import 'swiper/swiper.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/effect-fade/effect-fade.min.css';

import 'swiper/swiper-bundle.min.css';

import { Navigation } from "swiper";
import { Button } from 'react-bootstrap'
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import { Link } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { Col, Form, Row } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import FreeQuoteModal from '../component/FreeQuoteModal'
import { HEADER_MENU_LIST } from '../api/constant'
import Header from '../component/Header'
import { GET_SETTING_DATA } from '../api/constant'

function HeroBanner(props) {
  const [modalShow, setModalShow] = React.useState(false);

  const [navigationItems, setNavigationItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [setingData, setSetingData] = useState([])
 
  useEffect(() => {
    const getNavigationMenu = async () => {
      const res = await fetch(HEADER_MENU_LIST)
      const data = await res.json()
      if (data.status) {
        setNavigationItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getNavigationMenu();
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();
  }, []);

  // console.log('props', props.mainCatInfo);
  return (
    <>
     <Header setingData={setingData} />
      <div className='hero-banner category-banner'>
        {/* <Link to={"/"} className='logo'><img src={Logo} alt="" /></Link> */}
        <h1>Insuregora</h1>
       
        <Swiper navigation modules={[Navigation]} className="mySwiper banner-swiper">
          <SwiperSlide>
            <img className='side-img' src={props.mainCatInfo.background_image} alt={props.mainCatInfo.title} />
            <div className='hero-banner-imgs'>
              <img className='banner-bg-grad' src={bannerbggrad} alt="" />
              <img className='banner-vector' src={bannervector} alt="" />
              {/* <span className='banner-span'></span> */}
            </div>
            <div className='slider-main'>
              <div className='slider-left'>
                <h2>{props.mainCatInfo.title}</h2>
                <h4>{props.mainCatInfo.sub_title}<span className='common-grad'></span></h4>
                <p>{props.mainCatInfo.description}</p>
                <Link onClick={() => setModalShow(true)} className={`cutom-button category-hero-btn`}>Get a Free Quote <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
              </div>
              <div className='slider-right'>
                <span>Choose</span>
                <h3>Product to Get Started</h3>
                <p>Explore options for a one-time benefit that pays in <br /> the event of a covered fatal accident.</p>
                <div className='insurance-type'>
                  {props.categoryItems.length > 0 && props.categoryItems.map((categoryItem) => {
                    return <Link to={"/plan-details/" + categoryItem.slug + "/" + categoryItem.s_slug} className="insurance-box" key={categoryItem.id}>
                      <img className='insurance-img' src={categoryItem.icon_image} alt={categoryItem.sub_category_title} />
                      {categoryItem.sub_category_title}
                    </Link>
                  })}
                </div>
                {/* <a href="" className="call-us"><span>Call us at</span><img className='call-us-img' src={callicon} alt="" /><span>{props.setingData.office_phone_no}</span></a> */}
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <a href="#topInsurers" className="scroll-down">
        <img className='scroll-down-img' src={scrolldownimg} alt="" />
      </a>
      <FreeQuoteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}
export default HeroBanner