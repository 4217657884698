import React, { useEffect, useState } from 'react'
import buttonArow from '../assets/images/right-arrow.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import { Accordion } from 'react-bootstrap';
import { LIST_ALL_FAQ } from '../api/constant'
function FAQ() {
  const [faqItems, setfaqItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  useEffect(() => {
    const listAllFaqs = async () => {
      const res = await fetch(LIST_ALL_FAQ)
      const data = await res.json()
      if (data.status) {
        setfaqItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    listAllFaqs();

  }, []);

  return (<div className='FAQ'>
    <Container>
    <Row>
        <Col>
          <div className='common-title'>
            <h2>Frequently asked questions</h2>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Accordion defaultActiveKey="1">
            {faqItems.length > 0 && faqItems.map((faqItem) => {
              return <Accordion.Item eventKey={faqItem.id} key={faqItem.id}>
                <Accordion.Header>{faqItem.question}</Accordion.Header>
                <Accordion.Body>
                  {faqItem.answer}
                </Accordion.Body>
              </Accordion.Item >
            })}
          </Accordion>
        </Col>
      </Row>
    </Container>
  </div>
  )
}

export default FAQ