import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button } from 'react-bootstrap';
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import Calendar from '../assets/images/calendar.svg'
import ourartical1 from '../assets/images/our-artical-1.png'
import ourartical2 from '../assets/images/our-artical-2.png'
import ourartical3 from '../assets/images/our-artical-3.png'
import playbtn from '../assets/images/play.png'
import load from '../assets/images/load.png'
import Modal from 'react-bootstrap/Modal';
import { LIST_FIRST_ROW_ARTICLE } from '../api/constant';
import { Link } from 'react-router-dom'
function OurArticle() {
  const [articleRow, setArticlesRow] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [modalShow, setModalShow] = React.useState(false);
  const [videoUrl, setVideoUrl] = useState(null)
  const [videoTitle, setVideoTitle] = useState(null)

  useEffect(() => {
    const listRowArticle = async () => {
      const res = await fetch(LIST_FIRST_ROW_ARTICLE)
      const data = await res.json()
      if (data.status) {
        setArticlesRow(data.data)
      } else {
        setErrMsg(data.message)
      }
    }

    listRowArticle();
  }, []);

  const videoClickOpen = (videoLink, videoTitle) => {
    setVideoUrl(videoLink);
    setModalShow(true)
    setVideoTitle(videoTitle);
  };

  return (
    <>
      <div className='our-article common-padding'>
          <Row>
            <Col>
              <div className='common-title'>
                  <h2>Our Blog & Article</h2>               
              </div>
            </Col>
          </Row>
          <div className='our-article-group'>
            <Row>
              {articleRow.length > 0 && articleRow.map((articleRow, index) => {
                if (index === 0) {
                  return <Col md={12} lg={4} key={articleRow.id} >
                    <div className='our-article-box'>
                      <div className='our-article-video'>
                      <Link to={"/articles-details/" + articleRow.slug}><img src={articleRow.main_img} alt="" /></Link>
                        {articleRow.main_video && (
                          <Link to={""} className='play-btn' onClick={() => videoClickOpen(articleRow.main_video, articleRow.main_title)}><img src={playbtn} alt="" /></Link>
                        )}                        
                      </div>
                      <div className='our-article-text'>
                      <div className='date-box'><span><img src={Calendar} alt="" /></span>{articleRow.blog_date}</div>
                        <Link to={"/articles-details/" + articleRow.slug} className='play-btn' >{articleRow.main_title}</Link>
                        <p>{articleRow.short_text.substring(0, 200) + "..."}</p>
                        </div>
                    </div>
                  </Col>
                } else {
                  return <Col md={12} lg={4} key={articleRow.id} >
                    <div className='our-article-box' >
                      <div className='our-article-video'>
                        <Link to={"/articles-details/" + articleRow.slug}><img src={articleRow.main_img} alt="" /></Link>                        
                      </div>
                      <div className='our-article-text'>
                        <div className='date-box'><span><img src={Calendar} alt="" /></span> {articleRow.blog_date}</div>
                        <Link to={"/articles-details/" + articleRow.slug} className='play-btn' >
                          {articleRow.main_title}
                        </Link>
                        <p>{articleRow.short_text.substring(0, 200) + "..."}</p>
                      </div>
                    </div>
                  </Col>
                }
              })}
            </Row>
            <Link to={"/articles"}  className='cutom-button'>View More Articles <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
          </div>
      </div >
      <MyVerticallyCenteredModal
        videoUrl={videoUrl}
        title={videoTitle}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe width="100%" height="350" src={props.videoUrl} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Modal.Body>
    </Modal>
  );
}
export default OurArticle