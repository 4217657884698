import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../component/Header'
import Container from 'react-bootstrap/Container';
import ComparePricesAmong from '../component/ComparePricesAmong';
import WhyTrustInsuranceBottom from '../component/WhyTrustInsuranceBottom';
import different1 from '../assets/images/different-1.png';
import different2 from '../assets/images/different-2.png';
import lifeIcon from '../assets/images/life-icon.png';
import urmissionvaluesimg from '../assets/images/our-mission-values.png';
import ourmissionvaluesgroupimg1 from '../assets/images/passion-members.png';
import ourmissionvaluesgroupimg2 from '../assets/images/service-others.png';
import ourmissionvaluesgroupimg3 from '../assets/images/inclusion.png';
import ourmissionvaluesgroupimg4 from '../assets/images/collaborative-spirit.png';
import ourmissionvaluesgroupimg5 from '../assets/images/open-mindedness.png';
import { GET_ABOUT_DATA } from '../api/constant'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer';
function About() {
  const [errMsg, setErrMsg] = useState(null)
  const [setingData, setSetingData] = useState([])


  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();

  }, []);

  return (
    <>
      <Header setingData={setingData} />
      <div className='about-banner common-padding'>
        <div className='about-banner-grad'></div>
        <Container>
          <Row>
            <Col md={12} lg={6} xl={5}>
              <div className='about-banner-box'>
                <span className="banner-span"></span>
                <span>Thank you for your interest!!</span>
                <p>Your data has been submitted successfully!! We're going to get in touch with you shortly.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='top-insurers about-top-insurers-padding' id='topInsurers'>
        <Container>
          <ComparePricesAmong />
        </Container>
      </div>



      <Footer setingData={setingData} />
    </>
  )
}

export default About