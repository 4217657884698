import React, { useEffect, useState } from 'react'
import Header from '../component/Header'
import { Col, Container, Row } from 'react-bootstrap'
import ArticleList from '../component/ArticleList'
import ProductCategories from '../component/ProductCategories'
import SimilarBlog from '../component/SimilarBlog'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer'
import subBanner from '../assets/images/sub-banner.png'
function Articles() {
  const [errMsg, setErrMsg] = useState(null)
  const [setingData, setSetingData] = useState([])
  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();
  }, []);
  return (
    <>
      <Header setingData={setingData} />
      <div className='sub-banner'>
        <img src={subBanner} alt="" />
        <h1>Latest Article</h1>
      </div>
      <div className='article-main'>
          <Row>
            <Col md={12} lg={8}>
              <ArticleList />
            </Col>
            <Col md={12} lg={4}>
              <ProductCategories />
              {/* <SimilarBlog /> */}
            </Col>
          </Row>
      </div>
      <Footer setingData={setingData} />
    </>
  )
}

export default Articles