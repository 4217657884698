import React, { useEffect, useState } from 'react'
import Header from '../component/Header'
import ResultList from '../component/ResultList'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer'
function Result() {
  const [setingData, setSetingData] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();
  }, []);
  return (
    <>
      <Header setingData={setingData} />
      <ResultList />
      <Footer setingData={setingData} />
    </>
  )
}

export default Result