import React, { useEffect, useState } from 'react'
import HeroBanner from '../component/HeroBanner'
import TopInsurers from '../component/TopInsurers'
import ProtectYourFamily from '../component/ProtectYourFamily'
import OffersSeveral from '../component/OffersSeveral'
import WhyTrustInsurance from '../component/WhyTrustInsurance'
import OurArticle from '../component/OurArticle'
import Header from '../component/Header'
import { Routes, Route, useParams } from 'react-router-dom';
import { LIST_BY_CATEGORY } from '../api/constant'
import { GET_CATEGORY_INFO_BY_SLUG } from '../api/constant'
import { GET_SETTING_DATA } from '../api/constant'
import { GET_APPLY_PROCESS_PAGE } from '../api/constant'
import Footer from '../component/Footer'
import WhyTrustInsuranceBottom from '../component/WhyTrustInsuranceBottom';
function Category() {
  let { slug } = useParams();
  // console.log('slug', slug);
  const [categoryItems, setCategoryItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [categoryInfo, setCategoryInfo] = useState([])
  const [setingData, setSetingData] = useState([])
  const [applyData, setApplyData] = useState([])
  useEffect(() => {

    const getCategoryInfo = async () => {
      const res = await fetch(GET_CATEGORY_INFO_BY_SLUG + slug)
      const data = await res.json()
      if (data.status) {
        setCategoryInfo(data.data)
      } else {
        setErrMsg(data.message)
      }
    }

    const listAllCategory = async () => {
      const res = await fetch(LIST_BY_CATEGORY + slug)
      const data = await res.json()
      if (data.status) {
        setCategoryItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }

    getCategoryInfo();
    listAllCategory();

    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();

    const getApplyData = async () => {
      const res = await fetch(GET_APPLY_PROCESS_PAGE)
      const data = await res.json()
      if (data.status) {
        setApplyData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getApplyData();

  }, []);

  return (
    <>
      <div className='main category-page'>
        {/* <div className='category-header'><Header setingData={setingData} /></div> */}
        <HeroBanner categoryItems={categoryItems} mainCatInfo={categoryInfo} setingData={setingData} />
        <TopInsurers mainCatInfo={categoryInfo} />
        <ProtectYourFamily mainCatInfo={categoryInfo} categoryItems={categoryItems} />
        <OffersSeveral applyData={applyData} setingData={setingData}/>
        <WhyTrustInsurance />
        <WhyTrustInsuranceBottom mainCatInfo={categoryInfo} />
        <OurArticle />
      </div>
      <Footer setingData={setingData} />
    </>
  )
}

export default Category