import React, { useEffect, useState } from 'react'
import Header from '../component/Header'
import { Col, Container, Row } from 'react-bootstrap'
import { Routes, Route, useParams } from 'react-router-dom'
import ArticleDetailsComp from '../component/ArticleDetailsComp'
import ProductCategories from '../component/ProductCategories'
import SimilarBlog from '../component/SimilarBlog'
import { GET_ARTICLE_INFO_BY_SLUG } from '../api/constant'
import { GET_SIMILAR_ARTICLE_BY_SLUG } from '../api/constant'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer';
import subBanner from '../assets/images/sub-banner.png'
function ArticlesDetails() {
  let { slug } = useParams();
  const [articleItem, setArticleItem] = useState([])
  const [similarItems, setSimilarItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [setingData, setSetingData] = useState([])

  useEffect(() => {

    const getArticleInfo = async () => {
      const res = await fetch(GET_ARTICLE_INFO_BY_SLUG + slug)
      const data = await res.json()
      if (data.status) {
        setArticleItem(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getArticleInfo();

    const getSimilarArticles = async () => {
      const res = await fetch(GET_SIMILAR_ARTICLE_BY_SLUG + slug)
      const data = await res.json()
      if (data.status) {
        setSimilarItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSimilarArticles();

    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();

  }, []);
  return (
    <>
      <Header setingData={setingData} />
      <div className='sub-banner'>
        <img src={subBanner} alt="" />
        <h1>Latest Article</h1>
      </div>
      <div className='article-main article-detail--main common-padding '>
          <Row>
            <Col md={12} lg={8}>
              <ArticleDetailsComp articleItem={articleItem} setingData={setingData} />
            </Col>
            <Col md={12} lg={4}>
              <ProductCategories />
              <SimilarBlog similarItems={similarItems} />
            </Col>
          </Row>
      </div>
      <Footer setingData={setingData} />
    </>
  )
}

export default ArticlesDetails