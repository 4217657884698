import React, { useEffect, useState } from 'react'
import { CDBStepper, CDBStep, CDBBtn } from "cdbreact";
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { GET_PLAN_FORM_BY_SLUG } from "../api/constant";
import StepperForm from './StepperForm';
const Stepper = ({ subslug }) => {
  const [active, setActive] = useState(1);
  const [stepData, setStepData] = useState({});
  const [errMsg, setErrMsg] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const stepField = {}

  useEffect(() => {
    // console.log("isLoading 1 ", isLoading);
    const getPlanFormBySlug = async () => {
      // setIsLoading(true)
      //console.log("isLoading 2 ", isLoading);
      const res = await fetch(GET_PLAN_FORM_BY_SLUG + subslug)
      const data = await res.json()

      if (data.status) {
        //console.log('data', data.data[0]);
        setStepData(data.data[0])
        setIsLoading(false);
        //console.log("isLoading 3 ");

      } else {
        setErrMsg(data.message);
        setIsLoading(false);
      }
    }

    getPlanFormBySlug();

  }, []);

  if (stepData == null) {
    return <div>Sorry No data found!!</div>
  }

  if (Object.keys(stepData).length === 0) {
    return <div className="loader loader1"></div>
  }

  // console.log("stepData", stepData['stepsArr']);
  // console.log("isLoading 4 ", isLoading);
  isLoading === false && Object.entries(stepData['stepsArr']).forEach((step, index) => {
    // console.log('step', step);
    stepField[`Step${index + 1}`] = () => {
      return <StepperForm pageSlug={subslug} handleNextPrevClick={handleNextPrevClick} step={step[1]} stepIndex={index + 1} totalStep={stepData["total_step"]} catID={stepData["id"]} />;
    }
  })

  // console.log('stepField', stepField);
  const ComponentA = stepField["Step1"] || <h1>Component B</h1>;
  // console.log('ComponentA', ComponentA);


  const handleNextPrevClick = (a) => setActive(a);
  return (
    <CDBStepper steps={[]}>

      {Object.keys(stepField).map((step, index) => {
        // console.log("step22", stepField[step], step);
        const ComponentT = stepField[step]
        return <CDBStep
          key={index}
          id={index + 1}
          icon={`q${index}`}
          name={`Step ${index + 1}`}
          handleClick={() => handleNextPrevClick(index + 1)}
          active={active}
          component={<ComponentT />}
        />
      })}
    </CDBStepper>
  );
};

export default Stepper;

const Step4 = ({ handleNextPrevClick, ...props }) => {
  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer" >
        <Form>
          <Form.Group className="mb-3 form-group" controlId="formName">
            {/* <Form.Label>Name</Form.Label> */}
            <input className="form-control" type="text" placeholder="Enter Name" />
          </Form.Group>
          <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
            {/* <Form.Label>Email address</Form.Label> */}
            <input className="form-control" type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3 form-group" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <input className="form-control" type="password" placeholder="Password" />
          </Form.Group>

          <Form.Group className="mb-3 form-group">
            {/* <Form.Label>Check me out</Form.Label> */}
            <div className="check-group">
              <input id='CheckMeOut' type="checkbox" />
              <Form.Label className="w-full" htmlFor="CheckMeOut">Check me out</Form.Label>

            </div>
          </Form.Group>
          <Form.Group className="mb-3 form-group w-full">
            {/* <Form.Label>Select Menu</Form.Label> */}
            <select className="form-select" aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>Gender</Form.Label>
            <div className="radio-group">
              <input id='male' name='radio' type="radio" />
              <Form.Label htmlFor="male">Male</Form.Label>
              <input id='female' name='radio' type="radio" />
              <Form.Label htmlFor="female">Female</Form.Label>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-between w-full">
            <CDBBtn className="cutom-button" onClick={() => handleNextPrevClick(3)}>
              <img className='on-hover prevIcon' src={buttonArow} alt="" /> <img className='defailt prevIcon' src={buttonArowWhite} alt="" /> Previous
            </CDBBtn>
            <CDBBtn className="cutom-button" onClick={() => handleNextPrevClick(4)}>
              Finish <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" />
            </CDBBtn>
          </div>
        </Form>
      </div>
    </div>

  );
};

const Step3 = ({ handleNextPrevClick, ...props }) => {
  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer" >
        <Form>
          <Form.Group className="mb-3 form-group" controlId="formName">
            {/* <Form.Label>Name</Form.Label> */}
            <input className="form-control" type="text" placeholder="Enter Name" />
          </Form.Group>
          <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
            {/* <Form.Label>Email address</Form.Label> */}
            <input className="form-control" type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3 form-group" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <input className="form-control" type="password" placeholder="Password" />
          </Form.Group>

          <Form.Group className="mb-3 form-group">
            {/* <Form.Label>Check me out</Form.Label> */}
            <div className="check-group">
              <input id='CheckMeOut' type="checkbox" />
              <Form.Label className="w-full" htmlFor="CheckMeOut">Check me out</Form.Label>

            </div>
          </Form.Group>
          <Form.Group className="mb-3 form-group w-full">
            {/* <Form.Label>Select Menu</Form.Label> */}
            <select className="form-select" aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>Gender</Form.Label>
            <div className="radio-group">
              <input id='male' name='radio' type="radio" />
              <Form.Label htmlFor="male">Male</Form.Label>
              <input id='female' name='radio' type="radio" />
              <Form.Label htmlFor="female">Female</Form.Label>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-between w-full">
            <CDBBtn className="cutom-button" onClick={() => handleNextPrevClick(2)}>
              <img className='on-hover prevIcon' src={buttonArow} alt="" /> <img className='defailt prevIcon' src={buttonArowWhite} alt="" />  Previous
            </CDBBtn>
            <CDBBtn className="cutom-button" onClick={() => handleNextPrevClick(4)}>
              Continue <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" />
            </CDBBtn>
          </div>
        </Form>
      </div>
    </div>

  );
};

const Step2 = ({ handleNextPrevClick, ...props }) => {
  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer" >
        <Form>
          <Form.Group className="mb-3 form-group" controlId="formName">
            {/* <Form.Label>Name</Form.Label> */}
            <input className="form-control" type="text" placeholder="Enter Name" />
          </Form.Group>
          <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
            {/* <Form.Label>Email address</Form.Label> */}
            <input className="form-control" type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3 form-group" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <input className="form-control" type="password" placeholder="Password" />
          </Form.Group>

          <Form.Group className="mb-3 form-group">
            {/* <Form.Label>Check me out</Form.Label> */}
            <div className="check-group">
              <input id='CheckMeOut' type="checkbox" />
              <Form.Label className="w-full" htmlFor="CheckMeOut">Check me out</Form.Label>

            </div>
          </Form.Group>
          <Form.Group className="mb-3 form-group w-full">
            {/* <Form.Label>Select Menu</Form.Label> */}
            <select className="form-select" aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>Gender</Form.Label>
            <div className="radio-group">
              <input id='male' name='radio' type="radio" />
              <Form.Label htmlFor="male">Male</Form.Label>
              <input id='female' name='radio' type="radio" />
              <Form.Label htmlFor="female">Female</Form.Label>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-between w-full">
            <CDBBtn type="submit" className="cutom-button" onClick={() => handleNextPrevClick(1)}>
              <img className='on-hover prevIcon' src={buttonArow} alt="" /> <img className='defailt prevIcon' src={buttonArowWhite} alt="" /> Previous
            </CDBBtn>
            <CDBBtn className="cutom-button" onClick={() => handleNextPrevClick(3)}>
              Continue <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" />
            </CDBBtn>
          </div>
        </Form>
      </div>
    </div>
  );
};

const Step1 = ({ handleNextPrevClick, ...props }) => {
  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer">
        <Form>
          <Form.Group className="mb-3 form-group" controlId="formName">
            {/* <Form.Label>Name</Form.Label> */}
            <input className="form-control" type="text" placeholder="Enter Name" />
          </Form.Group>
          <Form.Group className="mb-3 form-group" controlId="formBasicEmail">
            {/* <Form.Label>Email address</Form.Label> */}
            <input className="form-control" type="email" placeholder="Enter email" />
          </Form.Group>

          <Form.Group className="mb-3 form-group" controlId="formBasicPassword">
            {/* <Form.Label>Password</Form.Label> */}
            <input className="form-control" type="password" placeholder="Password" />
          </Form.Group>

          <Form.Group className="mb-3 form-group">
            {/* <Form.Label>Check me out</Form.Label> */}
            <div className="check-group">
              <input id='CheckMeOut' type="checkbox" />
              <Form.Label className="w-full" htmlFor="CheckMeOut">Check</Form.Label>
            </div>
          </Form.Group>
          <Form.Group className="mb-3 form-group w-full">
            {/* <Form.Label>Select Menu</Form.Label> */}
            <select className="form-select" aria-label="Default select example">
              <option>Open this select menu</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </Form.Group>
          <Form.Group className="mb-3 form-group">
            <Form.Label>Gender</Form.Label>
            <div className="radio-group">
              <input id='male' name='radio' type="radio" />
              <Form.Label htmlFor="male">Male</Form.Label>
              <input id='female' name='radio' type="radio" />
              <Form.Label htmlFor="female">Female</Form.Label>
            </div>
          </Form.Group>
          <div className="d-flex justify-content-between w-full">
            <CDBBtn className="cutom-button" onClick={() => handleNextPrevClick(2)}>
              Continue <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" />
            </CDBBtn>
          </div>
        </Form>
      </div>
      <p>We don't sell your personal information to third parties</p>
    </div>
  );
};

