import React, { FunctionComponent, PropsWithChildren, useEffect, useState, useRef } from 'react'
import Logo from '../assets/images/INSUREGORA-LOGO.png'
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import headerCallIcon from '../assets/images/header-call-icon.png'
import { Button } from 'react-bootstrap'
import { Container } from 'react-bootstrap'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { HEADER_MENU_LIST } from '../api/constant'
import FreeQuoteModal from '../component/FreeQuoteModal'
import { Link } from 'react-router-dom'
import toast, { Toaster } from 'react-hot-toast';

const NavigationItemWithSubNav = (props) => {

  return (
    <NavDropdown renderMenuOnMount={true} title={props.navItems.title} id="collasible-nav-dropdown arrow-top">
      {props.navItems.sub_category.map((sub) => <NavDropdown.Item key={sub.sub_id + Math.random()} href={`/plan-details/${props.navItems.slug}/${sub.sub_slug}`}>{sub.sub_title}</NavDropdown.Item>)}
    </NavDropdown>
  )
}


function Header(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [navigationItems, setNavigationItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [isSticky, setSticky] = React.useState(false);

  const stickyRef = React.useRef(null);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 20) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const debounce = (func, wait = 20, immediate = true) => {
    let timeOut;
    return () => {
      let context = this,
        args = arguments;
      const later = () => {
        timeOut = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeOut;
      clearTimeout(timeOut);
      timeOut = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  window.addEventListener("scroll", debounce(handleScroll));
 
  useEffect(() => {
    const getNavigationMenu = async () => {
      const res = await fetch(HEADER_MENU_LIST)
      const data = await res.json()
      if (data.status) {
        setNavigationItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getNavigationMenu();

    window.addEventListener('scroll', () => {
      handleScroll();
  });

    
  }, []);


  return (
    <header>
      <Toaster
        position="top-center"
        reverseOrder={false}
      />
      <Navbar sticky={isSticky} className={isSticky ? "header-main fixed-top" : "header-main"} collapseOnSelect expand="lg" >
      {/* <Navbar   className="header-main" collapseOnSelect expand="lg" > */}
        
          <Navbar.Brand className='header-logo'><Link to={"/"} ><img src={Logo} alt="" /></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav>
              {navigationItems.length > 0 ? navigationItems.map((navItem) => {
                return navItem.sub_category.length > 0 ? <NavigationItemWithSubNav navItems={navItem} key={navItem.id} /> : <Nav.Link href="#" key={navItem.id}>{navItem.title}</Nav.Link>
              }) : ''}
            </Nav>
            <Nav className='d-block d-lg-none'>
              <div className="header-right">

                <Link onClick={() => setModalShow(true)} className='header-btn header-btn-get-free-quote' id="getFreeQuote">Get a Free Quote <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
                {/* <div className="header-call">
                  <a className='header-call-icon' href="tel:012-345-6789"><img src={headerCallIcon} alt="" /></a>
                  <div className='header-call-group'>
                    <p>Call us at</p>
                    <a className='header-call-text' href="tel:012-345-6789">{props.setingData.office_phone_no}</a>
                  </div>
                </div> */}
              </div>
            </Nav>
          </Navbar.Collapse>
          <Nav className='d-none d-lg-block'>
              <div className="header-right">

                <Link onClick={() => setModalShow(true)} className='header-btn header-btn-get-free-quote' id="getFreeQuote">Get a Free Quote <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>               
              </div>
            </Nav>
      </Navbar>
      <FreeQuoteModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </header>
  )
}

export default Header