import React, { useState } from 'react'
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import ourartical1 from '../assets/images/our-artical-1.png'
import playbtn from '../assets/images/play.png'
import articleBg from '../assets/images/article-bg.png'
import articleDetailsImg1 from '../assets/images/article-details-img.png'
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { POST_ARTICLE_COMMENT } from '../api/constant'
import toast, { Toaster } from 'react-hot-toast';
import Calendar from '../assets/images/calendar.svg'

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .required('First Name is Required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .required('Last Name is Required'),
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  phoneNumber: Yup.string()
    .min(10, 'Phone Number Must be 10 digit')
    .max(10, 'Phone Number Must be 10 digit')
    .required('Phone number is required'),
  message: Yup.string()
    .min(2, 'Too Short!')
    .required('Comment is required')
});


function ArticleDetailsComp(props) {

  const [modalShow, setModalShow] = React.useState(false);
  const [videoUrl, setVideoUrl] = useState(null)
  const [videoTitle, setVideoTitle] = useState(null)
  const articleId = props.articleItem.id;

  const videoClickOpen = (videoLink, videoTitle) => {
    setVideoUrl(videoLink);
    setModalShow(true)
    setVideoTitle(videoTitle);
  };
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        duration="50000"
      />
      <div className='articles-details'>
        <div className='article-box main-video'>
          {/* <img className='article-bg' src={articleBg} alt="" /> */}
          <div className='article-video'>
            <img src={props.articleItem.main_img} alt="" />
            {props.articleItem.main_video && (
              <Link to={""} className='play-btn' onClick={() => videoClickOpen(props.articleItem.main_video, props.articleItem.main_title)}><img src={playbtn} alt="" /></Link>
            )}
          </div>
          <div className="article-text">
            <div className='date-box'><span><img src={Calendar} alt="" /></span>{props.articleItem.blog_date}</div>
            <h2>{props.articleItem.main_title}</h2>
            <p>{props.articleItem.short_text}</p>
          </div>
        </div>
        <div className='article-box articles-details-box'>
          <div className="article-text article-editor-txt" dangerouslySetInnerHTML={{ __html: props.articleItem.description }} />
        </div>
      </div>
      {/* <div className='article-details-call'>
        <div className="article-details-call-bg"></div>
        <div className="article-details-call-text">
          <p>For more information about our services,</p>
          <a href="tel:012-345-6789">Call {props.setingData.office_phone_no}</a>
        </div>
      </div> */}
      <div className='article-details-reply'>
        <div className='common-title'>
          <h2 className='mb-0'>Leave a Reply</h2>
          <span>Your email message will not be published. Required fields are marked *</span>
        </div>
        <div className="leave-reply-form">
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              phoneNumber: '',
              message: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { resetForm }) => {
              // Submit Data
              values['blog_id'] = articleId;
              const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(values)
              };
              const res = await fetch(POST_ARTICLE_COMMENT, requestOptions);
              const data = await res.json()
              if (data.status) {
                toast.success('Your Comment has been submitted successfully!!');
                resetForm({
                  values: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phoneNumber: '',
                    message: ''
                  }
                });
              } else {
                toast.error(data.message);
              }
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (<Form onSubmit={handleSubmit} >
              <Row>
                <Col md={12} lg={6}>
                  <Form.Group controlId="validationFormik01">
                    <Form.Control type="text" placeholder="First Name *" name="first_name" value={values.first_name} onChange={handleChange} isInvalid={!!errors.first_name} />
                    <Form.Control.Feedback type="invalid">
                      {errors.first_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} lg={6}>
                  <Form.Group controlId="validationFormik01">
                    <Form.Control type="text" placeholder="Last Name *" name="last_name" value={values.last_name} onChange={handleChange} isInvalid={!!errors.last_name} />
                    <Form.Control.Feedback type="invalid">
                      {errors.last_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} lg={6} controlId="validationFormik02">
                  <Form.Group >
                    <Form.Control type="email" placeholder="Email Address*" name="email" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={12} lg={6}>
                  <Form.Group controlId="validationFormik03">
                    <Form.Control type="tel" placeholder="Phone Number *" name="phoneNumber" value={values.phoneNumber} onChange={handleChange} isInvalid={!!errors.phoneNumber} />
                    <Form.Control.Feedback type="invalid">
                      {errors.phoneNumber}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Group controlId="validationFormik05">
                    <Form.Control as="textarea" rows={3} placeholder="Leave us a comment*:" name="message" value={values.message} onChange={handleChange} isInvalid={!!errors.message} />
                    <Form.Control.Feedback type="invalid">
                      {errors.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button className="cutom-button" type="submit">Post a Comment<img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Button>
                </Col>
              </Row>
            </Form>
            )}
          </Formik>
        </div>
      </div>
      <MyVerticallyCenteredModal
        videoUrl={videoUrl}
        title={videoTitle}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe width="100%" height="350" src={props.videoUrl} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Modal.Body>
    </Modal>
  );
}

export default ArticleDetailsComp