import React from 'react';
// import buttonArow from '../assets/images/arrow.png'
// import buttonArowWhite from '../assets/images/arrow1.png'

import buttonArowWhite from '../assets/images/button-arrow-primary.svg'
import buttonArow from '../assets/images/button-arrow.svg'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
function WhyTrustInsuranceBottom(props) {
  return (
    <>
      <div className='why-trust-insurance-bottom'>
          <Row>
            <Col className='d-flex align-items-center insurance-product-group'>
              <h2 dangerouslySetInnerHTML={{ __html: props.mainCatInfo.see_all_insurance_product }}></h2>
              <Link className='custom-btn' to="">See All Insurance Product <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
            </Col>
          </Row>
      </div>

    </>
  )
}

export default WhyTrustInsuranceBottom