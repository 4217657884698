import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ao from '../assets/images/ao.png'
import atm from '../assets/images/atm.png'
import mwa from '../assets/images/mwa.png'
import aop from '../assets/images/aop.png'

import { Link } from 'react-router-dom';
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
function OffersSeveral(props) {
  let setingData = props.setingData;
  const openFreeQuoteForm = () => {
    const linkGetFreeQuote = document.getElementById('getFreeQuote');
    if (linkGetFreeQuote) {
      linkGetFreeQuote.click();
    }
  };
  return (
    <div className='offers-several'>
      <Row>
        <Col>
          <div className='common-title'>
            <span>{props.applyData.header_title}</span>
            <h2 dangerouslySetInnerHTML={{ __html: props.applyData.header_description }}/>
          </div>
        </Col>
      </Row>
      <div className="offers-several-step">
        <div className="offers-several-step-box">
          <img src={props.applyData.apply_online_icon} alt={props.applyData.apply_online_title} />
          <h3>{props.applyData.apply_online_title}</h3>
          <p>{props.applyData.apply_online_description}</p>
          <Link className='btn-arrow' onClick={openFreeQuoteForm}><img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
        </div>
        <div className="offers-several-step-box">
          <img src={props.applyData.apply_mail_icon} alt={props.applyData.apply_mail_title} />
          <h3>{props.applyData.apply_mail_title}</h3>
          <p>{props.applyData.apply_mail_description}</p>
          <Link className='btn-arrow' onClick={openFreeQuoteForm}><img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
        </div>
        <div className="offers-several-step-box">
          <img src={props.applyData.apply_with_agent_icon} alt={props.applyData.apply_with_agent_title} />
          <h3>{props.applyData.apply_with_agent_title}</h3>
          <p>{props.applyData.apply_with_agent_description}</p>
          <Link className='btn-arrow' onClick={openFreeQuoteForm}><img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
        </div>
        <div className="offers-several-step-box">
          <img src={props.applyData.apply_phone_icon} alt={props.applyData.apply_phone_title} />
          <h3>{props.applyData.apply_phone_title}</h3>
          <p>{props.applyData.apply_phone_description}</p>
          <Link className='btn-arrow' onClick={openFreeQuoteForm}><img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
        </div>
      </div>
    </div>
  )
}

export default OffersSeveral