import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import { Link } from 'react-router-dom'
function ProtectYourFamily(props) {
  return (
    <div className='protect-your-family'>
        <div className="protect-your-family-header">
          <Row>
            <Col>
              <div className='common-title'>
                <span>{props.mainCatInfo.header_title}</span>
                <h2>{props.mainCatInfo.header_sub_title}</h2>
                <p>{props.mainCatInfo.header_description}</p>
              </div>
            </Col>
          </Row>
        </div>
        {props.categoryItems.length > 0 && props.categoryItems.map((categoryItem) => {
          return <Row className={`whpyf-box ${categoryItem.even_class}`}>
            <Col lg={6} xl={6}>
              <div className='whpyf-box-img'>
                <img src={categoryItem.s_background_image} alt="{categoryItem.sub_category_title}" />
              </div>
            </Col>
            <Col lg={6} xl={6}>
              <div className='whpyf-box-text plan-sec'>
                {/* <img src={categoryItem.icon_image} alt="{categoryItem.sub_category_title}" /> */}
                <h2>{categoryItem.sub_category_title} <span className='common-grad'></span></h2>
                <p className='sub-tag'><b>{categoryItem.small_description}</b></p>
                <div dangerouslySetInnerHTML={{ __html: categoryItem.s_description }} />
                <Link to={"/plan-details/" + categoryItem.slug + "/" + categoryItem.s_slug} >
                  <Button className='cutom-button'>Select {categoryItem.sub_category_title} Product <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Button>
                </Link>
              </div>
            </Col>
          </Row>
        })}
    </div>
  )
}

export default ProtectYourFamily