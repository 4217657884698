import React from "react";
import Category from "./pages/Category";
import ProductDetails from "./pages/ProductDetails";
import Articles from "./pages/Articles";
import Result from "./pages/Result";
import About from "./pages/About";
import Career from "./pages/Career";
// import TermsCondition from './pages/TermsCondition'
// import Legal from './pages/Legal'
// import PrivacyPolicy from './pages/PrivacyPolicy'
import DynamicPage from "./pages/DynamicPage";
import FindForm from "./pages/FindForm";
import ThankyouEnquiry from "./pages/ThankyouEnquiry";
import "./App.scss";
import "./Variables.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ArticlesDetails from "./pages/ArticlesDetails";
import Home from "./pages/Home";
import ScrollToTop from "./helpers/ScrollToTop";
function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/category/:slug" Component={Category} />
          <Route path="/articles" Component={Articles} />
          <Route path="/articles-details/:slug" Component={ArticlesDetails} />
          <Route
            path="/plan-details/:mainslug/:subslug"
            Component={ProductDetails}
          />
          <Route path="/result" Component={Result} />
          <Route path="/about" Component={About} />
          <Route path="/thankyou" Component={ThankyouEnquiry} />
          <Route path="/career" Component={Career} />

          <Route path="/find-form" Component={FindForm} />
          <Route path="/page/:slug" Component={DynamicPage} />

          {/*
          <Route path='/legal' Component={Legal} />
          <Route path='/terms-condition' Component={TermsCondition} /> 
          <Route path='/privacy-policy' Component={PrivacyPolicy} />
           */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
