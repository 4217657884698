import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import liIcom from '../assets/images/chevrons-right.svg'
import { LIST_ALL_CATEGORY } from '../api/constant'
function ProductCategories() {
    const [category, setCategory] = useState([])
    const [errMsg, setErrMsg] = useState(null)
    useEffect(() => {
        const listAllCategory = async () => {
        const res = await fetch(LIST_ALL_CATEGORY)
        const data = await res.json()
        if (data.status) {
            setCategory(data.data)
        } else {
            setErrMsg(data.message)
        }
        }
        
        listAllCategory();
    }, []);

  return (
    <>
        <div className='product-categories'>
            <h3>Product Categories</h3>
            <div className="product-categories-list">
            <ul>
            {category.length > 0 ? category.map((catItem) => {
                return <li>
                    <Link to={"/category/" + catItem.slug}><img src={liIcom} alt="" />{catItem.title}</Link>
                </li>
                }) : ''}
            </ul>
            </div>
        </div>
    </>
  )
}

export default ProductCategories











