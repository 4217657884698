import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const cookiesAccepted = localStorage.getItem('cookiesAccepted');
        const cookiesClosed = localStorage.getItem('cookiesClosed');
        if (!cookiesAccepted && !cookiesClosed) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookiesAccepted', 'true');
        setIsVisible(false);
    };

    const handleClose = () => {
        localStorage.setItem('cookiesClosed', 'true');
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }
    
    return (
        <div className="cookie-consent">
            <div className="cookie-message">
                <button onClick={handleClose} className="close-button">&times;</button>
                <div className='d-flex flex-column'>
                    <div><p>We use cookies to help enhance a customized user experience on our website. By continuing to use this site, you agree to our use of cookies in accordance with our <a href="/page/privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and <a href="/page/terms-condition" target="_blank" rel="noopener noreferrer"> Terms and Conditions</a>. </p></div>
                </div>
                <div>
                    <button onClick={handleAccept} className="cookie-button">Accept</button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
