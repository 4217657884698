import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
// import 'swiper/swiper.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/swiper-bundle.min.css';

import { Navigation, Autoplay } from "swiper";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap'
import { LIST_ALL_CATEGORY } from '../api/constant'
import { LIST_ALL_BANNERS } from '../api/constant'
function HomeHeroBanner() {
  const [category, setCategory] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [bannersItems, setBanners] = useState([])
  const [activeIndex, setActiveIndex] = useState(0);
  const totalSlides = 3; 

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex);
  };
  useEffect(() => {
    const listAllCategory = async () => {
      const res = await fetch(LIST_ALL_CATEGORY)
      const data = await res.json()
      if (data.status) {
        setCategory(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    const listAllBanners = async () => {
      const res = await fetch(LIST_ALL_BANNERS)
      const data = await res.json()
      if (data.status) {
        setBanners(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    listAllCategory();
    listAllBanners();
  }, []);

  const render = category.length > 0 && category.map((item) => {
    return <Link to={"/category/" + item.slug} className={`insurance-box hero-btn hero-btn-${item.slug}`} key={item.id} >
      <img className='insurance-img' src={item.hover_icon_image} alt="" />
      <img className='insurance-img active' src={item.icon_image} alt="" />
      <h3>{item.title}</h3>
    </Link>
  })

  return (
    <>
      <div className='hero-banner'>       
        <Swiper navigation loop={true} autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }} modules={[Autoplay,Navigation]} className="mySwiper banner-swiper">
          {bannersItems.length > 0 && bannersItems.map((bannerItem) => {
            return <SwiperSlide key={bannerItem.id}>
              <img className='side-img' src={bannerItem.banner_img} alt="" /> 
                <div className='slider-main'>                
                  <div className='slider-left'>
                    <h2>Smarter Way</h2>
                    <h4>to buy insurance <span className='common-grad'></span></h4>
                    <p>Select a product to compare quotes:</p>
                    <div className='insurance-type'>
                      {render}
                    </div>
                  </div>
                </div>
            </SwiperSlide>
          })}
        </Swiper>
      </div>
    </>
  )
}
export default HomeHeroBanner