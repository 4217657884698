import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Header from '../component/Header'
import WhyTrustInsurance from '../component/WhyTrustInsurance'
import OurArticle from '../component/OurArticle'
import HomeHeroBanner from '../component/HomeHeroBanner'
import ComparePricesAmong from '../component/ComparePricesAmong'
import FAQ from '../component/FAQ'
import Footer from '../component/Footer'
import { GET_SETTING_DATA } from '../api/constant'
function Home() {
  const [setingData, setSetingData] = useState([])
  const [errMsg, setErrMsg] = useState(null)

  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();
  }, []);
  return (
    <>
      <div className='main home-page'>
        <Header setingData={setingData} />
        <HomeHeroBanner />
        <div className='top-insurers about-top-insurers-padding' id='topInsurers'>
          <Container>
            <ComparePricesAmong />
          </Container>
        </div>
        <WhyTrustInsurance />
        <FAQ />
        <OurArticle />
      </div>
      <Footer setingData={setingData} />
    </>
  )
}

export default Home