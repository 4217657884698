import React, { useEffect, useState } from 'react'
import Header from '../component/Header'
import OurArticle from '../component/OurArticle'
import Benefits from '../component/Benefits'
import RelatedProducts from '../component/RelatedProducts'
import { Routes, Route, useParams } from 'react-router-dom';
import { GET_PLAN_FORM_BY_SLUG } from '../api/constant'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer'
import subBanner from '../assets/images/sub-banner.png'
function ProductDetails() {
  let { mainslug } = useParams();
  let { subslug } = useParams();
  const [setingData, setSetingData] = useState([])
  const [planDetail, setPlanDetail] = useState([])
  const [planTitle, setPlanTitle] = useState('');
  const [errMsg, setErrMsg] = useState(null)

  
  useEffect(() => {
    const getPlanFormBySlug = async () => {
      try {
        const res = await fetch(GET_PLAN_FORM_BY_SLUG + subslug);
        const data = await res.json();
        if (data.status) {
          setPlanDetail(data.data);
        } else {
          setErrMsg(data.message);
        }
      } catch (error) {
        setErrMsg('Failed to fetch plan details.');
      }
    };

    const getSettingData = async () => {
      try {
        const res = await fetch(GET_SETTING_DATA);
        const data = await res.json();
        if (data.status) {
          setSetingData(data.data);
        } else {
          setErrMsg(data.message);
        }
      } catch (error) {
        setErrMsg('Failed to fetch settings.');
      }
    };

    getPlanFormBySlug();
    getSettingData();
  }, [subslug]);

  // Set plan title on the plan details screen
  const renderPlanTitle = (planName = "") => {
    setPlanTitle(planName);
  }

  return (
    <>
      <Header setingData={setingData} />
      <div className='sub-banner'>
        <img src={subBanner} alt="" />
        <h1>{planTitle}</h1>
      </div>
      <Benefits mainslug={mainslug} subslug={subslug} setingData={setingData} renderPlanTitle={renderPlanTitle}/>
      <RelatedProducts mainslug={mainslug} subslug={subslug} />
      <OurArticle />
      <Footer setingData={setingData} />
    </>
  )
}

export default ProductDetails