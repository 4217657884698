import React, { useEffect, useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Col, Form, Row } from "react-bootstrap";
import { experience } from "react-bootstrap";
import { CDBStepper, CDBStep, CDBBtn } from "cdbreact";
import { Toaster, toast } from "react-hot-toast";
import { POST_APPLY_JOB } from "../api/constant";
import buttonArow from "../assets/images/button-arrow-white.svg";
import modalClose from "../assets/images/modal-close.svg";
import buttonArowPrimary from "../assets/images/button-arrow-primary.svg";
import buttonArowWhite from "../assets/images/button-arrow-hover.svg";
import buttonArowWhite2 from "../assets/images/button-arrow-white.svg";

function CareerForm(props) {

  const [active, setActive] = useState(1);
  const handleNextPrevClick = (step) => {
    //setActive(step);
  };

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        duration="50000"
      />
      <div className="career-form">
        <div className="career-form-header">
          <p className="career-form-header-top">Apply here</p>
          <h3>Please fill your basic details.</h3>
        </div>
        <CDBStepper steps={[]}>
          <CDBStep
            id={1}
            icon="check"
            name="My Information"
            handleClick={() => handleNextPrevClick(1)}
            active={active}
            component={
              <Step1
                active={active}
                setActive={setActive}
                jobId={props.job_id}
                jobTitle={props.job_title}
                handleNextPrevClick={handleNextPrevClick}
              />
            }
          />
          <CDBStep
            id={2}
            icon="check"
            name="Voluntary Disclosures"
            handleClick={() => handleNextPrevClick(2)}
            active={active}
            component={
              <Step2
                active={active}
                setActive={setActive}
                handleNextPrevClick={handleNextPrevClick}
              />
            }
          />
          <CDBStep
            id={3}
            icon="check"
            name="Self Identity"
            handleClick={() => handleNextPrevClick(3)}
            active={active}
            component={
              <Step3
                active={active}
                setActive={setActive}
                handleNextPrevClick={handleNextPrevClick(3)}
              />
            }
          />
        </CDBStepper>
        <Toaster />
      </div>
    </>
  );
}

export default CareerForm;

const Step1 = (props) => {
  const [jobTitle, setJobTitle] = useState(props.jobTitle);
  const [jobId, setJobId] = useState(props.jobId);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [file, setFile] = useState("");
  const [about, setAbout] = useState("");
  const [errors, setErrors] = useState({});

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    if (!value) {
      setErrors({ ...errors, firstName: "First Name is required" });
    } else {
      setErrors({ ...errors, firstName: "" });
    }
  };

  useEffect(() => {
    //if (props.active === 1) {
      var retrievedObject = JSON.parse(localStorage.getItem('firstForm'));
      if (retrievedObject !== null) {
        setFirstName(retrievedObject.firstName);
        setLastName(retrievedObject.lastName);
        setPhone(retrievedObject.phone);
        setEmail(retrievedObject.email);
        setAbout(retrievedObject.about);
        setJobTitle(retrievedObject.jobTitle);
        setJobId(retrievedObject.jobId);
        setFile(retrievedObject.file);
      }
    //}
  }, [props.active]);

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    if (!value) {
      setErrors({ ...errors, lastName: "Last Name is required" });
    } else {
      setErrors({ ...errors, lastName: "" });
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setErrors({ ...errors, email: "Please enter a valid email address" });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhone(value);
      setErrors({ ...errors, phone: "" });
    } else {
      setErrors({ ...errors, phone: "Phone Number must contain only digits" });
    }
  };


  const handleFileChange = (e) => {
    const fileObj = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Read file content as base64 and store in state
      setFile(reader.result);
    };

    if (fileObj) {
      reader.readAsDataURL(fileObj); // Read file as data URL (base64)
      setErrors({ ...errors, file: "" });
    } else {
      setErrors({ ...errors, file: "Resume file is required" });
    }
  };

  const handleAboutChange = (e) => {
    const value = e.target.value;
    setAbout(value);
    if (!value) {
      setErrors({ ...errors, about: "Tell us about yourself is required" });
    } else {
      setErrors({ ...errors, about: "" });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!email) newErrors.email = "Email Address is required";
    if (!phone) newErrors.phone = "Phone Number is required";
    if (!file) newErrors.file = "Resume is required";
    if (!about) newErrors.about = "Tell us about yourself is required";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Please fill out all required fields.");
      return;
    }
    let formDataObj = {
      jobId: props.jobId,
      jobTitle: props.jobTitle,
      firstName: firstName,
      lastName: lastName,
      file: file,
      email: email,
      phone: phone,
      about: about
    }

    localStorage.setItem('firstForm', JSON.stringify(formDataObj));
    props.setActive(2);
  };
  return (
    <div>
      <div className="StepContainer">
        <div className="FlexColumnContainer">
          <form onSubmit={handleSubmit}>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                name="firstName"
                placeholder="First Name*"
                value={firstName}
                onChange={handleFirstNameChange}
              />
              {errors.firstName && (
                <div className="error-text">{errors.firstName}</div>
              )}
            </div>

            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                name="lastName"
                placeholder="Last Name*"
                value={lastName}
                onChange={handleLastNameChange}
              />
              {errors.lastName && (
                <div className="error-text">{errors.lastName}</div>
              )}
            </div>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Email Address*"
                value={email}
                onChange={handleEmailChange}
              />
              {errors.email && <div className="error-text">{errors.email}</div>}
            </div>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="tel"
                name="phone"
                placeholder="Phone Number*"
                value={phone}
                onChange={handlePhoneChange}
              />
              {errors.phone && <div className="error-text">{errors.phone}</div>}
            </div>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                readOnly
                value={jobTitle}
                placeholder="Job Title*"
              />
            </div>

            <div className="mb-3 form-group">
              <div className="">
                <input
                  className="form-control"
                  id="file-upload-input"
                  type="file"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                />
                {errors.file && (
                  <div className="error-text">
                    {errors.file}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 form-group w-full">
              <textarea
                name="about"
                rows={5}
                placeholder="Tell us about yourself*"
                value={about}
                onChange={handleAboutChange}
              />
              {errors.about && <div className="error-text">{errors.about}</div>}
            </div>

            <div className="d-flex justify-content-between w-full">
              <CDBBtn className="cutom-button" type="submit">
                Next <img className="default" src={buttonArow} alt="" />{" "}
                <img className="on-hover" src={buttonArowWhite} alt="" />
              </CDBBtn>
            </div>
          </form>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

const Step2 = (props) => {
  //  complete form
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  disability
  const [showDisability, setShowDisability] = useState(false);
  const handleDisabilityClose = () => setShowDisability(false);
  const handleDisabilityShow = () => setShowDisability(true);

  //  Terms and Conditions
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const handleTermsConditionsClose = () => setShowTermsConditions(false);
  const handleTermsConditionsShow = () => setShowTermsConditions(true);

  const [disability, setDisability] = useState('Yes');


  useEffect(() => {
    if (props.active === 1) {
      var retrievedObject = JSON.parse(localStorage.getItem('secondForm'));
      if (retrievedObject !== null) {
        setDisability(retrievedObject.disability)
      }
    }
  }, [props.active]);

  const handleDisabilityChange = (e) => {
    setDisability(e.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    let formDataObj = {
      disability: disability,
    }
    localStorage.setItem('secondForm', JSON.stringify(formDataObj));
    props.setActive(3);
  }

  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer">
        <form onSubmit={handleSubmit}>
          <div className="mb-3 form-group w-full">
            <label className="checkbox-header">
              Please check one of the boxes below if you have a disability?
              <span>*</span>
            </label>
            <div className="radio-group">
              <input
                id="checkOne"
                name="radio"
                type="radio"
                value="Yes"
                checked={disability === "Yes"}
                onChange={handleDisabilityChange}
              />
              <label htmlFor="checkOne">
                Yes, I have a disability, or have had one in the past
              </label>

              <input
                id="checkTwo"
                name="radio"
                type="radio"
                value="No"
                checked={disability === "No"}
                onChange={handleDisabilityChange}
              />
              <label htmlFor="checkTwo">
                No, I do not have a disability and have not had one in the past
              </label>

              <input
                id="checkThree"
                name="radio"
                type="radio"
                value="I do not want to answer"
                checked={disability === "I do not want to answer"}
                onChange={handleDisabilityChange}
              />
              <label htmlFor="checkThree">I do not want to answer</label>
            </div>
          </div>

          <div className="mb-3 form-group w-full">
            <div className="form-info">
              <p>
                Why are you being asked to complete this form?{" "}
                <a href="javascript:;" onClick={handleShow}>
                  Know more <img src={buttonArowPrimary} alt="" />
                </a>
              </p>
              <p>
                How do you know if you have a disability?{" "}
                <a href="javascript:;" onClick={handleDisabilityShow}>
                  Know more <img src={buttonArowPrimary} alt="" />
                </a>
              </p>
            </div>
          </div>


          <div className="d-flex justify-content-between w-full">
            <button
              type="button"
              className="cutom-button me-4"
              onClick={() => props.setActive(1)}
            >
              Back <img className="defailt" src={buttonArow} alt="" />{" "}
              <img className="on-hover" src={buttonArowWhite} alt="" />
            </button>
            <button type="submit" className="cutom-button">
              Next <img className="defailt" src={buttonArow} alt="" />{" "}
              <img className="on-hover" src={buttonArowWhite} alt="" />
            </button>
          </div>
        </form>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="common-modal"
        backdropClassName="common-backdrop-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Why are you being asked to complete this form?{" "}
          </Modal.Title>
          <Button onClick={handleClose}>
            <img src={modalClose} alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            We are a federal contractor or subcontractor. The law requires us to
            provide equal employment opportunity to qualified people with
            disabilities. We have a goal of having at least 7% of our workers as
            people with disabilities. The law says we must measure our progress
            towards this goal. To do this, we must ask applicants and employees
            if they have a disability or have ever had one. People can become
            disabled, so we need to ask this question at least every five years.
          </p>
          <p>
            Completing this form is voluntary, and we hope that you will choose
            to do so. Your answer is confidential. No one who makes hiring
            decisions will see it. Your decision to complete the form and your
            answer will not harm you in any way. If you want to learn more about
            the law or this form, visit the U.S. Department of Labor’s Office of
            Federal Contract Compliance Programs (OFCCP) website at
            www.dol.gov/ofccp. How do you know if you have a disability
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTermsConditions}
        onHide={handleTermsConditionsClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="common-modal"
        backdropClassName="common-backdrop-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Terms and Conditions</Modal.Title>
          <Button onClick={handleTermsConditionsClose}>
            <img src={modalClose} alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            We collects the following categories of personal information to
            contact you and evaluate your application for employment: Name and
            Address; Email Address; Phone Number; Occupation and Education.
          </p>
          <p>
            I understand that any misrepresentation or material omission made by
            me on this application will be sufficient cause for cancellation of
            this application or immediate discharge from the employer's service,
            whenever it is discovered.
          </p>
          <p>
            I give the employer the right to contact and obtain information from
            all references, employers, educational institutions, and to
            otherwise verify the accuracy of the information contained in this
            application. I hereby release from liability the employer and its
            representatives for seeking, gathering and using such information
            and all other persons, corporations or organizations for furnishing
            such information.
          </p>
          <p>
            If I am hired, I understand that I am free to resign at any time
            with or without cause and without prior notice, and the employer
            reserves the same right to terminate my employment at any time, with
            or without cause and without prior notice, except as may be required
            by law. This application does not constitute a contract for
            employment for any specified period. I understand that no
            representative of the employer, other than an authorized officer,
            has the authority to make any assurances to the contrary. I further
            understand that any such assurances must be in writing and signed by
            an authorized officer.
          </p>
          <p>
            I understand it is this company's policy not to refuse to hire a
            qualified individual with a disability because of that person's need
            for a reasonable accommodation as required by the ADA.
          </p>
          <p>
            I understand that if I am hired, I will be required to provide proof
            of identity and legal work authorization.
          </p>
          <p>
            I understand that I am not obligated to disclose sealed or expunged
            records of conviction or arrest.
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDisability}
        onHide={handleDisabilityClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="common-modal"
        backdropClassName="common-backdrop-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>How do you know if you have a disability? </Modal.Title>
          <Button onClick={handleDisabilityClose}>
            <img src={modalClose} alt="" />
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            A disability is a condition that substantially limits one or more of
            your “major life activities.” If you have or have ever had such a
            condition, you are a person with a disability.{" "}
            <span>Disabilities include, but are not limited to:</span>
          </p>
          <div className="disabilities">
            <ul>
              <li>
                Alcohol or other substance use disorder (not currently using
                drugs illegally)
              </li>
              <li>
                Autoimmune disorder, for example, lupus, fibromyalgia,
                rheumatoid arthritis, HIV/AIDS
              </li>
              <li>Blind or low vision</li>
              <li>Cancer (past or present)</li>
              <li>Cardiovascular or heart disease</li>
              <li>Celiac disease</li>
              <li>Cerebral palsy</li>
              <li>Deaf or serious difficulty hearing</li>
              <li>Diabetes</li>
            </ul>
            <ul>
              <li>
                Disfigurement, for example, disfigurement caused by burns,
                wounds, accidents, or congenital disorders
              </li>
              <li>Epilepsy or other seizure disorde</li>
              <li>
                Gastrointestinal disorders, for example, Crohn's Disease,
                irritable bowel syndrome
              </li>
              <li>Intellectual or developmental disability</li>
              <li>
                Mental health conditions, for example, depression, bipolar
                disorder, anxiety disorder, schizophrenia, PTSD
              </li>
              <li>Missing limbs or partially missing limbs</li>
              <li>
                Mobility impairment, benefiting from the use of a wheelchair,
                scooter, walker, leg brace(s) and/or other supports
              </li>
            </ul>
            <ul>
              <li>
                Nervous system condition, for example, migraine headaches,
                Parkinson’s disease, multiple sclerosis (MS)
              </li>
              <li>
                Neurodivergence, for example, attention-deficit/hyperactivity
                disorder (ADHD), autism spectrum disorder, dyslexia, dyspraxia,
                other learning disabilities
              </li>
              <li>Partial or complete paralysis (any cause)</li>
              <li>
                Pulmonary or respiratory conditions, for example, tuberculosis,
                asthma, emphysema
              </li>
              <li>Short stature (dwarfism)</li>
              <li>Traumatic brain injury</li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const Step3 = (props) => {

  const [nationality, setNationality] = useState('Yes');
  const [race, setRace] = useState([]);
  const [veteran, setVeteran] = useState("I identity as one or more of the classifications of protected veteran listed below");
  const [termsCondition, setTermsCondition] = useState(false); // Use boolean for checkbox state

  const [nationalityError, setNationalityError] = useState('');
  const [raceError, setRaceError] = useState('');
  const [veteranError, setVeteranError] = useState('');
  const [termsConditionError, setTermsConditionError] = useState('');

  const handleChangeNationality = (e) => {
    setNationality(e.target.value);
  };

  const handleChangeRace = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setRace([...race, value]); // Add to array
    } else {
      setRace(race.filter(item => item !== value)); // Remove from array
    }
  };

  const handleChangeVeteran = (e) => {
    setVeteran(e.target.value);
  };

  const handleChangeTermsCondition = (e) => {
    setTermsCondition(e.target.checked);
  };


  function handleSubmit(event) {
    event.preventDefault();

    // Perform validation
    let formValid = true;

    if (!nationality) {
      setNationalityError('Please select your Hispanic origin.');
      formValid = false;
    } else {
      setNationalityError('');
    }

    if (race.length === 0) {
      setRaceError('Please select at least one race.');
      formValid = false;
    } else {
      setRaceError('');
    }

    if (!veteran) {
      setVeteranError('Please confirm your veteran status.');
      formValid = false;
    } else {
      setVeteranError('');
    }

    if (!termsCondition) {
      setTermsConditionError('You must agree to the terms and conditions.');
      formValid = false;
    } else {
      setTermsConditionError('');
    }

    if (formValid) {
      
      var personalInformation = JSON.parse(localStorage.getItem('firstForm'));
      if (personalInformation === null) {
        toast.error('Please fill out personal information.');
        return;
      }

      var disabilityInformation = JSON.parse(localStorage.getItem('secondForm'));
      if (disabilityInformation === null) {
        toast.error('Please fill out disability information.');
        return;
      }

      const formDataObj = new FormData();
      formDataObj.append('job_id', personalInformation.jobId);
      formDataObj.append('job_title', personalInformation.jobTitle);
      formDataObj.append('first_name', personalInformation.firstName);
      formDataObj.append('last_name', personalInformation.lastName);
      formDataObj.append('email', personalInformation.email);
      formDataObj.append('phone_no', personalInformation.phone);
      formDataObj.append('resume_file', personalInformation.file);
      formDataObj.append('tell_us_about_your_self', personalInformation.about);

      formDataObj.append('is_disability', disabilityInformation.disability);

      formDataObj.append('nationality', nationality);
      formDataObj.append('race', race);
      formDataObj.append('veteran', veteran);

      const options = {
        method: 'POST',
        body: formDataObj,
      };
    
      fetch(POST_APPLY_JOB, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json(); // Assuming response is JSON, adjust as needed
        })
        .then((data) => {
          if(data.status === false){
            toast.error(data.message);
          }else{
            // Handle form submission
            localStorage.removeItem("firstForm");
            localStorage.removeItem("secondForm");
            props.setActive(1);
            toast.success(data.message);
          }
        })
        .catch((error) => {
          toast.error(error);
          return;
        });
    }
  }


  return (
    <div className="StepContainer">
      <Toaster
        position="top-center"
        reverseOrder={false}
        duration="50000"
      />
      <div className="FlexColumnContainer">

        <form onSubmit={handleSubmit}>

          <div className="mb-4 form-group w-full">
            <div className="form-info">
              <p>Please answer the question about hispanic origin and the question about race. For this survey, Hispanic origins are not races.
                Hispanic origin can viewed as the heritage, nationality, lineage, or country of birth of your parents or ancestors  before arriving
                In the United States. People who identify as Hispanic, Latino, or Spanish may be any race.</p>
            </div>
          </div>


          <div class="mb-4 form-group">
            <label for="veteranStatus1" class="form-label">Are you of Hispanic, Latino, or Spanish origin ?</label>
            <div className="radio-group">
              <input
                id="checkOne"
                name="radio"
                type="radio"
                value="Yes"
                checked={nationality === "Yes"}
                onChange={handleChangeNationality}
              />
              <label htmlFor="checkOne">
                Yes
              </label>

              <input
                id="checkTwo"
                name="radio"
                type="radio"
                value="No"
                checked={nationality === "No"}
                onChange={handleChangeNationality}
              />
              <label htmlFor="checkTwo">
                No
              </label>

              <input
                id="checkThree"
                name="radio"
                type="radio"
                value="I prefer not to answer"
                checked={nationality === "I prefer not to answer"}
                onChange={handleChangeNationality}
              />
              <label htmlFor="checkThree">I prefer not to answer</label>
              {nationalityError && <div className="text-danger">{nationalityError}</div>}
            </div>
          </div>

          <div className="mb-4 form-group">
            <label htmlFor="race" className="form-label">
              What is your race? Please select all that apply. (Required)
            </label>
            <div className="check-group d-flex flex-column justify-content-start align-items-start">
              <div className="form-check mr-2">
                <input
                  id="raceWhite"
                  name="race"
                  type="checkbox"
                  value="White"
                  checked={race.includes("White")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceWhite" className="form-check-label">
                  White
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceBlack"
                  name="race"
                  type="checkbox"
                  value="Black or African American"
                  checked={race.includes("Black or African American")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceBlack" className="form-check-label">
                  Black or African American
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceNativeAmerican"
                  name="race"
                  type="checkbox"
                  value="Native American or Alaska Native"
                  checked={race.includes("Native American or Alaska Native")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceNativeAmerican" className="form-check-label">
                  Native American or Alaska Native
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceAsian"
                  name="race"
                  type="checkbox"
                  value="Asian"
                  checked={race.includes("Asian")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceAsian" className="form-check-label">
                  Asian
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="racePacificIslander"
                  name="race"
                  type="checkbox"
                  value="Native Hawaiian, Samoan, or other Pacific Islander"
                  checked={race.includes("Native Hawaiian, Samoan, or other Pacific Islander")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="racePacificIslander" className="form-check-label">
                  Native Hawaiian, Samoan, or other Pacific Islander
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceOther"
                  name="race"
                  type="checkbox"
                  value="Other"
                  checked={race.includes("Other")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceOther" className="form-check-label">
                  Other
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="racePreferNotToAnswer"
                  name="race"
                  type="checkbox"
                  value="I prefer not to answer"
                  checked={race.includes("I prefer not to answer")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="racePreferNotToAnswer" className="form-check-label">
                  I prefer not to answer
                </label>
              </div>
            </div>
            {raceError && <div className="text-danger">{raceError}</div>}
          </div>


          <div class="mb-4 form-group">
            <label for="veteranStatus2" class="form-label">Please confirm your veteran status :</label>
            <div className="radio-group">
              <input
                id="checkTwo1"
                name="veteran"
                type="radio"
                value="I identity as one or more of the classifications of protected veteran listed below"
                checked={veteran === "I identity as one or more of the classifications of protected veteran listed below"}
                onChange={handleChangeVeteran}
              />
              <label htmlFor="checkTwo1">
                I identity as one or more of the classifications of protected veteran listed below
              </label>

              <input
                id="checkThree1"
                name="veteran"
                type="radio"
                value="I am not a protected veteran"
                checked={veteran === "I am not a protected veteran"}
                onChange={handleChangeVeteran}
              />
              <label htmlFor="checkThree1">I am not a protected veteran</label>

              <input
                id="checkOne1"
                name="veteran"
                type="radio"
                value="I do not wish to answer"
                checked={veteran === "I do not wish to answer"}
                onChange={handleChangeVeteran}
              />
              <label htmlFor="checkOne1">
                I do not wish to answer
              </label>

            </div>
            {veteranError && <div className="text-danger">{veteranError}</div>}
          </div>

          <div class="mb-3 form-group w-full">
            <div class="check-group">
              <input id="CheckMeOut" type="checkbox" checked={termsCondition} class="form-check-input" onChange={handleChangeTermsCondition} />
              <label class="form-check-label" for="CheckMeOut">
                Yes, I have read and consent to the <a href="/terms-condition">terms and conditions</a>.
              </label>
            </div>
            {termsConditionError && <div className="text-danger">{termsConditionError}</div>}
          </div>

          <div className="d-flex justify-content-between w-full">
            <CDBBtn
              className="cutom-button"
              onClick={() => props.setActive(2)}
            >
              Back <img className="defailt" src={buttonArow} alt="" />{" "}
              <img className="on-hover" src={buttonArowWhite} alt="" />
            </CDBBtn>
            <CDBBtn
              className="cutom-button"
              type="submit"
            >
              Finish <img className="defailt" src={buttonArow} alt="" />{" "}
              <img className="on-hover" src={buttonArowWhite} alt="" />
            </CDBBtn>
          </div>
        </form>
      </div>
    </div>
  );
};
