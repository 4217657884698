import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from '../component/Header'
import Container from 'react-bootstrap/Container';
import ComparePricesAmong from '../component/ComparePricesAmong';
import { GET_ABOUT_DATA } from '../api/constant'
import { GET_SETTING_DATA } from '../api/constant'
import Footer from '../component/Footer';
function About() {
  const [aboutUsData, setAboutUsData] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [setingData, setSetingData] = useState([])


  useEffect(() => {

    const getAboutData = async () => {
      const res = await fetch(GET_ABOUT_DATA)
      const data = await res.json()
      if (data.status) {
        setAboutUsData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getAboutData();

    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA)
      const data = await res.json()
      if (data.status) {
        setSetingData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getSettingData();

  }, []);

  return (
    <>
      <Header setingData={setingData} />
      <div className='about-banner common-padding'>
          <video id="background-video" width="100%" autoplay="autoplay" controls="" muted="" src={aboutUsData.header_banner} preload="auto" loop></video>
        <div className='about-banner-grad'></div>
        <Container>
          <Row>
            <Col md={12} lg={6} xl={6}>
              <div className='about-banner-box'>
                <span className="banner-span"></span>
                <span>{aboutUsData.header_title}</span>
                <h1>{aboutUsData.header_sub_title}</h1>
                <p>{aboutUsData.header_description}</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className='top-insurers about-top-insurers-padding' id='topInsurers'>
        <Container>
          <ComparePricesAmong />
        </Container>
      </div>
      <div className='insur-different common-padding'>
        <Container>
          <Row className='mb-70'>
            <Col md={12} lg={6}>
              <div className='insur-different-img'>
                <img className='different1' src={aboutUsData.main_image} alt="" />
                <img className='different2' src={aboutUsData.small_image} alt="" />
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className='insur-different-text'>
                <div className="about-title">
                  <h2>{aboutUsData.main_title} </h2><span className="common-grad"></span>
                </div>
                <div dangerouslySetInnerHTML={{ __html: aboutUsData.main_description }} />
              </div>
            </Col>
          </Row>
        </Container>
      </div >
      <div className='life-insurance'>
        <div className='life-insurance-left'>
          <div className='life-insurance-left-sub'>
            <div className='life-insurance-left-group'>
              <div className='life-insurance-left-icon'>
                <img src={aboutUsData.left_box_img} alt={aboutUsData.left_box_title} />
              </div>
              <h2>{aboutUsData.left_box_title}</h2>
            </div>
            <p>{aboutUsData.left_box_description}</p>
          </div>
          <div className='life-insurance-left-main'>
            <h3>{aboutUsData.left_sub_title}</h3>
            <span className="common-grad"></span>
            <p>{aboutUsData.left_sub_description}</p>
          </div>
        </div>
        <div className='life-insurance-right'>
          <div className='life-insurance-right-sub'>
            <div className='life-insurance-right-group'>
              <div className='life-insurance-left-icon'>
                <img src={aboutUsData.right_box_img} alt="" />
              </div>
              <h2>{aboutUsData.right_box_title}</h2>
            </div>
            <p>{aboutUsData.right_box_description}</p>
          </div>
          <div className='life-insurance-right-main'>
            <h3>{aboutUsData.right_sub_title}</h3>
            <span className="common-grad"></span>
            <p>{aboutUsData.right_sub_description}</p>
          </div>
        </div>
      </div>
      <div className="our-mission-values common-padding">
        <div className='our-mission-values-wrap'> 
            <Row>
              <Col sm={12}>
                <div className='common-title'>
                  {/* <span>{aboutUsData.mission_title}</span> */}
                  <h2>{aboutUsData.mission_sub_title}</h2>
                </div>
              </Col>
              <Col md={12} lg={4}>
                <Row>
                  <Col md={12} lg={12}>
                    <div className='our-mission-values-img-main'>
                      <img className="our-mission-values-img" src={aboutUsData.mission_image} alt="" />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className='our-mission-values-img-main center-img'>
                      <img className="our-mission-values-img" src={aboutUsData.mission_image} alt="" />
                    </div>
                  </Col>
                  <Col xs={6}>
                    <div className='our-mission-values-img-main center-img'>
                      <img className="our-mission-values-img" src={aboutUsData.mission_image} alt="" />
                    </div>
                  </Col>
                  <Col md={12} lg={12} className='mb-4 mb-md-0'>
                    <div className='our-mission-values-img-main'>
                      <img className="our-mission-values-img" src={aboutUsData.mission_image} alt="" />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={8}>
                <div className="our-mission-values-box" dangerouslySetInnerHTML={{ __html: aboutUsData.mission_description }}>
                </div>
              </Col>
            </Row>
          </div>
      </div>
      {/* <div className="about-why-trust-insurance-bottom">
        <WhyTrustInsuranceBottom />
      </div> */}
      <Footer setingData={setingData} />
    </>
  )
}

export default About