import React, { useEffect, useState, useCallback } from 'react'
import playbtn from '../assets/images/play.png'
import articleBg from '../assets/images/article-bg.png'
import load from '../assets/images/load.png'
import Modal from 'react-bootstrap/Modal';
import { LIST_ALL_ARTICLE } from '../api/constant';
import { Link } from 'react-router-dom'
import Calendar from '../assets/images/calendar.svg'
function ArticleList() {
  const [articleItems, setArticleItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  const [modalShow, setModalShow] = React.useState(false);
  const [videoUrl, setVideoUrl] = useState(null)
  const [videoTitle, setVideoTitle] = useState(null)
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPage, setTotalPage] = useState(0)
  const [displayLoadBtn, setDisplayLoadBtn] = useState(false)

  const listAllArticle = async (nextPage) => {
    nextPage = nextPage + 1;
    setLoading(true)
    setDisplayLoadBtn(false);
    const res = await fetch(LIST_ALL_ARTICLE + "?page=" + nextPage)
    const data = await res.json()
    if (data.status && data.data.articles) {
      const resultData = data.data;
      setArticleItems((articleItems) => [...articleItems, ...resultData.articles])
      setPage(resultData.pagination.current);
      setTotalPage(resultData.pagination.numPages);
      setLoading(false);
      if (resultData.pagination.numPages > resultData.pagination.current) {
        setDisplayLoadBtn(true);
      }
    } else {
      setErrMsg(data.message)
      setLoading(false);
    }
  }

  useEffect(() => {
    listAllArticle(page);
  }, []);

  const loadMore = useCallback((newPage) => {
    listAllArticle(newPage)
  }, [])

  const videoClickOpen = (videoLink, videoTitle) => {
    setVideoUrl(videoLink);
    setModalShow(true)
    setVideoTitle(videoTitle);
  };

  return (
    <>
      {/* <div className='common-title'>
        <h2>Latest Article</h2> */}
        {/* <span>Lorem Ipsum is simpl mmy text of the etting industry</span> */}
      {/* </div> */}
      <div className='articles-list'>
        {articleItems.length > 0 && articleItems.map((articleItem, index) => {
          if (index === 0) {
            return <div className='article-box main-video'>
              {/* <img className='article-bg' src={articleBg} alt="" /> */}
              <div className='article-video'>
              <Link to={"/articles-details/" + articleItem.slug}><img src={articleItem.main_img} alt="" /></Link>
                {articleItem.main_video && (
                  <Link to={""} className='play-btn' onClick={() => videoClickOpen(articleItem.main_video, articleItem.main_title)}><img src={playbtn} alt="" /></Link>
                )}
              </div>
              <div className='our-article-text'>
                  <div className='date-box'><span><img src={Calendar} alt="" /></span>{articleItem.blog_date}</div>
                  <Link to={"/articles-details/" + articleItem.slug} className='play-btn' >{articleItem.main_title}</Link>
              </div>
            </div>
          } else {
            return <div className='article-box'>
              <div className='article-video'>
              <Link to={"/articles-details/" + articleItem.slug}><img src={articleItem.main_img} alt="" /></Link>
                {articleItem.main_video && (
                  <Link to={""} className='play-btn' onClick={() => videoClickOpen(articleItem.main_video, articleItem.main_title)}><img src={playbtn} alt="" /></Link>
                )}
                {/* <span className='date-box'>{articleItem.blog_date}</span> */}
              </div>
              <div className='article-text'>
                <div className='date-box'><span><img src={Calendar} alt="" /></span>{articleItem.blog_date}</div>
                <Link to={"/articles-details/" + articleItem.slug} className='play-btn' >
                  {articleItem.main_title}
                </Link>
                <p>{articleItem.short_text}</p>
              </div>
            </div>
          }
        })}
        {displayLoadBtn &&
          <div className="load-more">
            <button disabled={loading} onClick={() => loadMore(page)}>
              <p>
              {loading && (
                  <img src={load} alt="" />
                  )}
                {loading ? 'Loading...' : ' LOAD MORE'}
              </p>
            </button>
          </div>
        }

      </div>
      <MyVerticallyCenteredModal
        videoUrl={videoUrl}
        title={videoTitle}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  )
}
function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe width="100%" height="350" src={props.videoUrl} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      </Modal.Body>
    </Modal>
  );
}

export default ArticleList