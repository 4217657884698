import React, { useEffect, useState } from "react";
import Header from "../component/Header";
import subBanner from "../assets/images/sub-banner.png";
import JobList from "../component/JobList";
import Footer from "../component/Footer";
import { GET_SETTING_DATA } from "../api/constant";

import buttonArowWhite from "../assets/images/button-arrow-hover.svg";
import buttonArowWhite2 from "../assets/images/button-arrow-white.svg";

import careerWhyWorkWithImg from "../assets/images/career-why-work-with-img.png";
import { Button, Col, Form, Row } from "react-bootstrap";



function Career() {
  const [errMsg, setErrMsg] = useState(null);

  const [setingData, setSetingData] = useState([]);

  // const handleNextPrevClick = (a: number) => setActive(a);

  useEffect(() => {
    const getSettingData = async () => {
      const res = await fetch(GET_SETTING_DATA);
      const data = await res.json();
      if (data.status) {
        setSetingData(data.data);
      } else {
        setErrMsg(data.message);
      }
    };
    getSettingData();
  }, []);


 

  return (
    <>
      <Header setingData={setingData} />
      <div className="sub-banner">
        <img src={subBanner} alt="" />
        <h1>Career</h1>
      </div>

      <div className="career-main">
        <Row>
          <Col>
            <div className="common-title">
              <h2>Current Openings</h2>
              <p>We're always looking for talented people</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <JobList></JobList>
          </Col>
        </Row>
      </div>
      <div className="insur-different career-why-work-with">
        <Row className="mb-70">
          <Col md={12} lg={6}>
            <div className="insur-different-img">
              <img className="different1" src={careerWhyWorkWithImg} alt="" />
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="insur-different-text">
              <div className="about-title">
                <p>Why Work With </p>
                <h2>Insuregora</h2>
                <span className="common-grad"></span>
              </div>
              <p>
                What sets us apart from other service providers is our
                commitment to personalization, community engagement, and
                open-mindedness. We understand that each person needs are
                unique, and we offer tailored solutions that resonate with their
                individual circumstances. Beyond our dedication to serving our
                members, we extend our support to the wider community, embodying
                a strong sense of responsibility to make a positive impact.
                Embracing diverse perspectives and innovative approaches, we
                foster an open-minded culture that continually seeks progressive
                solutions to evolving challenges. With trust, integrity, and a
                passion for what we do, we stand out as a provider dedicated to
                not only safeguarding your well-being but also enriching your
                life and the lives of those around you.
              </p>
              <a href="#" className="cutom-button">
                Join Our Team
                <img className="on-hover" src={buttonArowWhite} alt="" />{" "}
                <img className="defailt" src={buttonArowWhite2} alt="" />
              </a>
            </div>
          </Col>
        </Row>
      </div>

      <Footer setingData={setingData} />
    </>
  );
}

export default Career;
