import React, { Fragment } from 'react'
import { CDBBtn } from "cdbreact";
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import shieldStar from '../assets/images/shield-star.svg'
import { Form } from 'react-bootstrap'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { GET_RESULT } from '../api/constant';
import { Toaster, toast } from 'react-hot-toast';
import DatePicker from "react-datepicker";
import { useDispatch } from 'react-redux'
import { storeResult, storeUserInfo } from '../store/resultSlice'
import Autocomplete from '@mui/material/Autocomplete';
import { FormHelperText } from '@material-ui/core';
import TextField from '@mui/material/TextField';
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from 'react-router-dom';

const StepperForm = ({ pageSlug, handleNextPrevClick, step, stepIndex, catID, totalStep }) => {
  const dispatch = useDispatch();
    const navigate = useNavigate();
  const validationRules = {}
  const checkboxFields = []
  const dateFields = []
  const selectFields = []
  // console.log("Step from form", step);
  step.forEach((item) => {
    if (item.field_type === 'Checkbox' ) {
      if(item.is_require==1){
        validationRules[item.column_name] = Yup.array().min(1, "Required").required('Required')
      }else{
        validationRules[item.column_name] = Yup.array();
      }
      checkboxFields.push(item.column_name)
    } else if (item.field_type === 'Date') {
      if(item.is_require==1){
        validationRules[item.column_name] = Yup.date().required('Required')
      }else{
        validationRules[item.column_name] = Yup.date()
      }
      dateFields.push(item.column_name)
    } else if (item.field_type === 'Dropdown Selection') {
      if(item.is_require==1){
        validationRules[item.column_name] = Yup.string().required('Required').notOneOf([`Select ${item.element_name} Option`], 'default value is not allowed')
      }else{
        validationRules[item.column_name] = Yup.string()
      }
      selectFields.push(item.column_name)
    } else {
      if(item.is_require==1){
        if(item.validation_type=='email'){
          validationRules[item.column_name] = Yup.string().email().required('Required')
        }else if(item.validation_type=='numeric'){
          validationRules[item.column_name] = Yup.string().required('Required').matches(/^[0-9]+$/, 'Only numbers are allowed').max(15, 'Maximum 15 characters allowed')
        }else if(item.validation_type=='alpha'){
          validationRules[item.column_name] = Yup.string().required('Required').matches(/^[a-zA-Z]*$/, 'Only alphabetic characters are allowed')
        }else if(item.validation_type=='alpha_mumeric'){
          validationRules[item.column_name] = Yup.string().required('Required').matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed')
        }else{
          validationRules[item.column_name] = Yup.string().required('Required')
        }
      }else{
        if(item.validation_type=='email'){
          validationRules[item.column_name] = Yup.string().email()
        }else if(item.validation_type=='numeric'){
          validationRules[item.column_name] = Yup.string().matches(/^[0-9]+$/, 'Only numbers are allowed').max(15, 'Maximum 15 characters allowed')
        }else if(item.validation_type=='alpha'){
          validationRules[item.column_name] = Yup.string().matches(/^[a-zA-Z]*$/, 'Only alphabetic characters are allowed')
        }else if(item.validation_type=='alpha_mumeric'){
          validationRules[item.column_name] = Yup.string().matches(/^[a-zA-Z0-9]*$/, 'Only alphanumeric characters are allowed')
        }else{
          validationRules[item.column_name] = Yup.string()
        }
      }
    }

  })
  const validationSchema = Yup.object().shape(validationRules)
  const test = Object.keys(validationRules).reduce((acc, key) => {
    if (checkboxFields.includes(key)) {
      acc[key] = []
    } else if (dateFields.includes(key)) {
      const currentDate = new Date();
      acc[key] = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`
    } else {
      acc[key] = '';
    }
    return acc;
  }, {})

  
const dynamicWidth = 100/totalStep;
var temp = document.querySelectorAll(".step-content .steps");
for (var i = 0; i < temp.length; i++) {
  temp[i].style.width = dynamicWidth+"%";
}
  // console.log("validationRules", validationRules);
  // console.log("test", test);
  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer">
        <Toaster
          position="top-center"
          reverseOrder={false}
          duration="50000"
        />
        <Formik
          initialValues={test}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            // console.log('value init',values);
            // Submit Data
            if (stepIndex === 1) {
                localStorage.removeItem("requestBody");
            }
            
            let storageBody = {};
            if (stepIndex>1) {
              storageBody = JSON.parse(localStorage.getItem('requestBody'));
              values = { ...storageBody, ...values}
              
              // console.log('value update',values);
            }

            const requestBody = {
              sub_category_id: catID,
              isfinal: stepIndex === totalStep ? 1 : 0,
              step: stepIndex,
              ...values
            }

            localStorage.setItem('requestBody', JSON.stringify(values));

            const requestOptions = {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(requestBody)
            };
            const res = await fetch(GET_RESULT, requestOptions);
            const data = await res.json()
            if (data.status) {
              handleNextPrevClick(stepIndex + 1)
              if (stepIndex === totalStep) {
                // console.log("data",data);
                dispatch(storeResult(data.data.result))
                dispatch(storeUserInfo(data.data.userinfo))
                navigate('/result')
                localStorage.removeItem("requestBody");
              }
            } else {
              toast.error(data.message);
            }

          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (<Form className={`step-${stepIndex}-${pageSlug}`} name={`step-${stepIndex}-${pageSlug}`} onSubmit={handleSubmit}>
          
            {step.map((item, index) => {
              const stepToDisplay = item
              return <Fragment key={item.field_id + index}>{(ComponentMapping[item.field_types_id])(stepToDisplay, errors, handleChange, values, setFieldValue)}</Fragment>
            })}

            <div className="d-flex justify-content-start align-items-center w-full btn-information-group">
              
              <Link type="submit" className={`cutom-button mt-0 btn-${stepIndex}-${pageSlug}`} onClick={handleSubmit} onSubmit={handleSubmit} >
                {stepIndex === totalStep ? 'Finish' : 'Continue'} <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" />
              </Link>
              <p className='information-tag m-0'> <img className='defailt' src={shieldStar} alt="" />  We don't sell your personal information to third parties </p>
            </div>
          </Form>
          )}
        </Formik>
      </div>
      
    </div>
  );
};

const InputBox = (props, errors, handleChange, values) => {

  return <Form.Group className="mb-3 form-group" controlId={props.field_id}>
    <Form.Label>{props.element_name}<span className={(props.is_require==1 ? 'required':'optional')}>*</span></Form.Label>
    <Form.Control type="text" name={props.column_name} value={values.column_name} onChange={handleChange} isInvalid={!!errors[props.column_name]} />
    <Form.Control.Feedback type="invalid">
    {props.element_name} is {errors[props.column_name]}
    </Form.Control.Feedback>
  </Form.Group>
}
const RadioBoxField = (props, errors, handleChange, values) => {
  return <Form.Group className="mb-3 form-group">

    <Form.Label>{props.element_name}<span className={(props.is_require==1 ? 'required':'optional')}>*</span></Form.Label>
  <div className='input-btn-group'>
    {Object.entries(props.option_values).map((option, index) => {
      // console.log('option',option[0]);
      return <React.Fragment key={props.field_id + index}>
        {/* <input id={option[1]} name='radio' type="radio" /> */}
        <Form.Check type="radio" name={props.column_name} onChange={handleChange} isInvalid={!!errors[props.column_name]} value={option[0]} label={option[1]} />
      </React.Fragment>
    })}
  </div>
    <FormHelperText>{errors[props.column_name]
            ? `${props.element_name} is `+errors[props.column_name]
            : ''}</FormHelperText>
    
  </Form.Group>;
}

const CheckBoxField = (props, errors, handleChange, values) => {
  return <Form.Group className="mb-3 form-group">
    <Form.Label>{props.element_name}<span className={(props.is_require==1 ? 'required':'optional')}>*</span></Form.Label>
  <div className='input-btn-group'>
    {Object.entries(props.option_values).map((option, index) => {
      return <React.Fragment key={props.field_id + index}>
        {/* <input id={option[1]} name={props.column_name} type="checkbox" /> */}
        <Form.Check type="checkbox" name={props.column_name} onChange={handleChange} isInvalid={!!errors[props.column_name]} value={option[0]} label={option[1]} />
      </React.Fragment>
    })}
  </div>

<FormHelperText>{errors[props.column_name]
            ? `${props.element_name} is `+errors[props.column_name]
            : ''}</FormHelperText>
  </Form.Group>;
}

const DropDownField = (props, errors, handleChange, values, setFieldValue) => {
  // console.log("errors",errors);
  const OPTIONS_LIMIT = 10;
  const options = Object.entries(props.option_values).map(([value, label]) => ({ value, label }));
  const filterOptions = (options, { inputValue }) => {
    const filteredOptions = options.filter(option =>
      option.label.toLowerCase().includes(inputValue.toLowerCase()) &&
      option.label.toLowerCase().indexOf(inputValue.toLowerCase()) === 0
    );
    return filteredOptions.slice(0, 10);
  };


  // console.log('options',options);
  return (
    <Form.Group className={(props.is_require==1 ? 'mb-3 form-group auto-required':'mb-3 form-group auto-optional')}>
    <Autocomplete
    aria-label={props.element_name}
    name={props.column_name}
    options={options}
    filterOptions={filterOptions}
    onChange={(event, newValue) => { 
    const selectedValue = newValue && newValue.value !== undefined ? newValue.value : "";
    setFieldValue(props.column_name,selectedValue) }}
    isOptionEqualToValue={(option, value) => option.value === value.label}
    getOptionLabel={(option) => option.label}
    renderInput={(params) => (
      <TextField InputProps={{...params.InputProps,type: 'search',}} placeholder={`Select ${props.element_name} Option`} label={props.element_name} {...params} name={props.column_name} variant="outlined" error={!!errors[props.column_name]} />
    )}
  />
  <FormHelperText>{errors[props.column_name]
            ? `${props.element_name} is `+errors[props.column_name]
            : ''}</FormHelperText>
  </Form.Group>
)
}

const DropDownField_o = (props, errors, handleChange, values) => {

  const options = Object.entries(props.option_values).map(([value, label], index) => (
    <option key={index} value={value} label={label} />
  ));
  return <Form.Group className="mb-3 form-group w-full">
    <Form.Label>{props.element_name}<span className={(props.is_require==1 ? 'required':'optional')}>*</span></Form.Label>
    <Form.Select className="form-select" aria-label={props.element_name} name={props.column_name} value={values.state} onChange={handleChange} isInvalid={!!errors[props.column_name]}>
      <option>Select {props.element_name} Option</option>
      {options}
    </Form.Select>
    <Form.Control.Feedback type="invalid">
      {errors[props.column_name]}
    </Form.Control.Feedback>
  </Form.Group>
}




const DateField = (props, errors, handleChange, values, setFieldValue) => {
  
  return <Form.Group className="mb-3 form-group">
    <Form.Label>{props.element_name}<span className={(props.is_require==1 ? 'required':'optional')}>*</span></Form.Label>
    {Object.entries(props.option_values).map((option, index) => {
      return <React.Fragment key={props.field_id}>
        <DatePicker showMonthDropdown showYearDropdown dropdownMode="select" className="form-control w-full" dateFormat="yyyy.MM.dd" name={props.column_name} onChange={val => { setFieldValue(props.column_name, `${val.getFullYear()}-${val.getMonth() + 1}-${val.getDate()}`) }} selected={(values[props.column_name] && new Date(values[props.column_name]))} required maxDate={new Date()} />
        {/* <DatePicker className="form-control w-full" selected={startDate} onChange={(date) => setStartDate(date)} />       */}
      </React.Fragment>
    })}
    <Form.Control.Feedback type="invalid">
      {errors.firstName}
    </Form.Control.Feedback>
  </Form.Group>
}

const ComponentMapping = {
  1: InputBox,
  2: CheckBoxField,
  3: RadioBoxField,
  4: DropDownField,
  5: DateField
}

export default StepperForm
