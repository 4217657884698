import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import result1 from '../assets/images/result-1.png'
import result2 from '../assets/images/result-2.png'
import result3 from '../assets/images/result-3.png'
import star from '../assets/images/star-new.svg'
import toplevel from '../assets/images/top-level-new.svg'
import popular from '../assets/images/popular.png'
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { GET_RESULT_PAGE_DATA } from '../api/constant'
function ResultList() {
  const searchResult = useSelector((state) => state.result)
  const [resultPageData, setResultPageData] = useState([])
  const [errMsg, setErrMsg] = useState(null)

  useEffect(() => {

    const getResultPageData = async () => {
      const res = await fetch(GET_RESULT_PAGE_DATA)
      const data = await res.json()
      if (data.status) {
        setResultPageData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getResultPageData();

  }, []);

  return (
    <div className='result-list-main'>
        <Row className='result-list'>
          <Col xs={12}>
            <div className='result-list-box-text'>
              <h2>{resultPageData.page_title}</h2>
              <p>{resultPageData.description}</p>
            </div>
          </Col>
          <Col lg={12}>
            <div className='result-list-group'>
              {
                searchResult.planInfo.length > 0 && searchResult.planInfo.map((plan) => {
                  return <div className='result-list-box' key={plan.id}>
                    <img className='result-icon' src={plan.company_logo} alt="" />
                    <img className='star' src={star} alt="" />
                    <div className='btn-group'>
                      <a href={plan.compnay_link}><Button className='cutom-button'>Click To See Details <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Button></a>
                      
                    </div>
                    <img className='level' src={toplevel} alt="" />
                  </div>
                })||<div className='result-list-box-text no-result-found'>
                <h2>Sorry! No result matching found.</h2>
              </div>
              }
            </div>
          </Col>
        </Row>
    </div>
  )
}

export default ResultList