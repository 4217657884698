import React, { useEffect, useState } from 'react'
// Import Swiper styles
// import 'swiper/swiper.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/effect-fade/effect-fade.min.css';
import 'swiper/swiper-bundle.min.css';
import { Button } from 'react-bootstrap'
import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import { Link, useNavigate } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal';
import { Col, Form, Row } from 'react-bootstrap'
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { GET_SUBCATEGORY_LIST } from '../api/constant'
import { POST_FREE_QUOTE_DATA } from '../api/constant'
import { LIST_ALL_CATEGORY } from '../api/constant'
import { GET_ALL_STATES } from '../api/constant'
import { LIST_BY_CATEGORY } from '../api/constant'
import { GET_ALL_CITY_BY_STATE } from '../api/constant'
import toast, { Toaster } from 'react-hot-toast';
var phoneRegEx = '/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/';

const SignupSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('First Name required'),
  last_name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Last Name required'),
  address: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  city: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  state: Yup.string()
    .required('Required')
    .notOneOf(['State *'], 'default value is not allowed'),
  email: Yup.string()
    .email('Email Address is not valid')
    .required('Email is required'),
  phone_number: Yup.number()
    .typeError("Phone number is not valid")
    .required('Phone number is required'),
  hear_about_us: Yup.string()
    .required('Required')
    .notOneOf(['How did you hear about us? *'], 'default value is not allowed'),
  insurance_plan_type: Yup.string()
    .required('Required')
    .notOneOf(['Select Insurance Plan Type *'], 'default value is not allowed'),
  insurance_plan: Yup.string()
    .required('Required')
    .notOneOf(['Select Insurance Plan *'], 'default value is not allowed'),
});

function FreeQuoteModal(props) {
  const [errMsg, setErrMsg] = useState(null)
  const [planData, setPlanData] = useState([])
  const [category, setCategory] = useState([])
  const [stateData, setStateData] = useState([])
  const [cityData, setCityData] = useState([])
  const [category_slug, setCategorySlug] = useState(null)
  const [subcategory_slug, setSubCategorySlug] = useState(null)
  const navigate = useNavigate();
  useEffect(() => {
    const listAllCategory = async () => {
      const res = await fetch(LIST_ALL_CATEGORY)
      const data = await res.json()
      if (data.status) {
        setCategory(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    listAllCategory();

    const getAllStatesList = async () => {
      const res = await fetch(GET_ALL_STATES)
      const data = await res.json()
      if (data.status) {
        setStateData(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    getAllStatesList();

  }, []);

  const listAllCategory = async (slug) => {
    const res = await fetch(LIST_BY_CATEGORY + slug)
    const data = await res.json()
    if (data.status) {
      setPlanData(data.data)
    } else {
      setErrMsg(data.message)
    }
  }

  const listAllCity = async (slug) => {
    const res = await fetch(GET_ALL_CITY_BY_STATE + slug)
    const data = await res.json()
    if (data.status) {
      setCityData(data.data)
    } else {
      setErrMsg(data.message)
    }
  }

  const handleCustomChange = (field, form) => (event) => {
    const newValue = event.target.value; // You can apply your custom logic here
    form.setFieldValue(field.name, newValue);
    if(field.name=='insurance_plan_type'){
      setCategorySlug(newValue);
      listAllCategory(newValue);
    }
    if(field.name=='insurance_plan'){
      setSubCategorySlug(newValue);
    }
    if(field.name=='state'){
      listAllCity(newValue);
    }
  };

  return (
    <Modal
      {...props}

      aria-labelledby="contained-modal-title-vcenter"
      centered
      dialogClassName="modal-90w"
      className='connect-with-agent'>
      <Toaster
        position="top-center"
        reverseOrder={false}
        duration="50000"
      />
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <h2>Connect with an Agent <span className="common-grad"></span></h2>
        <p>Please provide your information, and an agent will contact you.</p>
        <Row>
          <Col>
            <Formik
              initialValues={{
                first_name: '',
                last_name: '',
                address: '',
                city: '',
                state: '',
                email: '',
                phone_number: '',
                hear_about_us: '',
                insurance_plan_type: '',
                insurance_plan: '',
              }}
              validationSchema={SignupSchema}
              onSubmit={async (values, { resetForm }) => {
                // Submit Data
                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify(values)
                };
                const res = await fetch(POST_FREE_QUOTE_DATA, requestOptions);
                const data = await res.json()
                if (data.status) {
                  // toast.success('Your data has been submitted successfully!! Uur team will contact you soon');
                  navigate("/plan-details/"+category_slug+"/"+subcategory_slug);
                  resetForm({
                    values: {
                      first_name: '',
                      last_name: '',
                      address: '',
                      city: '',
                      state: '',
                      email: '',
                      phone_number: '',
                      hear_about_us: '',
                      insurance_plan_type: '',
                      insurance_plan: '',
                    }
                  });
                } else {
                  setErrMsg(data.message)
                  toast.error(data.message);
                }

              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (<Form name="connect-with-agent-form" className='connect-with-agent-form' onSubmit={handleSubmit} >
                <Row>
                  <Col md={12} lg={6}>
                    <Form.Group controlId="validationFormik01">
                      <Form.Control type="text" placeholder="First Name *" name="first_name" value={values.first_name} onChange={handleChange} isInvalid={!!errors.first_name} />
                      <Form.Control.Feedback type="invalid">
                        {errors.first_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6}>
                    <Form.Group controlId="validationFormik02">
                      <Form.Control type="text" placeholder="Last Name *" name="last_name" value={values.last_name} onChange={handleChange} isInvalid={!!errors.last_name} />
                      <Form.Control.Feedback type="invalid">
                        {errors.last_name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12}>
                    <Form.Group controlId="validationFormik03">
                      <Form.Control as="textarea" rows={3} placeholder="Address *" name="address" value={values.address} onChange={handleChange} isInvalid={!!errors.address} />
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col md={12} lg={6}>
                    <Form.Group controlId="validationFormik05">
                      <Field name="state" render={({ field, form }) => (
                        <>
                        <Form.Select className="form-select" aria-label="Default select example" name="state" value={values.state} onChange={handleCustomChange(field, form)} isInvalid={!!errors.state}>
                          <option>Select State *</option>
                          {stateData.length > 0 && stateData.map((stateDataItem, index) => {
                            return <option value={stateDataItem.state_code}>{stateDataItem.state}</option>
                          })}
                        </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.state}
                          </Form.Control.Feedback> 
                          </>
                      )} />
                    </Form.Group>
                  </Col>

                  <Col md={12} lg={6}>
                    <Form.Group controlId="validationFormik04">
                      <Field name="city" render={({ field, form }) => (
                        <>
                        <Form.Select className="form-select" aria-label="Default select example" name="city" value={values.city} onChange={handleCustomChange(field, form)} isInvalid={!!errors.city}>
                          <option>Select City *</option>
                          {cityData.length > 0 && cityData.map((cityDataItem, index) => {
                            return <option value={cityDataItem.id}>{cityDataItem.city}</option>
                          })}
                        </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.city}
                          </Form.Control.Feedback> 
                          </>
                      )} />
                    </Form.Group>
                  </Col>

                  
                  
                  <Col md={12} lg={6} controlId="validationFormik06">
                    <Form.Group >
                      <Form.Control type="email" placeholder="Email *" name="email" value={values.email} onChange={handleChange} isInvalid={!!errors.email} />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={6}>
                    <Form.Group controlId="validationFormik07">
                      <Form.Control type="tel" placeholder="Phone Number *" name="phone_number" value={values.phone_number} onChange={handleChange} isInvalid={!!errors.phone_number} />
                      <Form.Control.Feedback type="invalid">
                        {errors.phone_number}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12}>
                    <Form.Group controlId="validationFormik08">
                      <Form.Select className="form-select" aria-label="Default select example" name="hear_about_us" value={values.hear_about_us} onChange={handleChange} isInvalid={!!errors.hear_about_us}>
                        <option>How did you hear about us? *</option>
                        <option value="News">News</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="Other">Other</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.hear_about_us}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12}>
                    <Form.Group controlId="validationFormik09">
                      <Field name="insurance_plan_type" render={({ field, form }) => (
                        <>
                        <Form.Select className="form-select" aria-label="Default select example" name="insurance_plan_type" value={values.insurance_plan_type} onChange={handleCustomChange(field, form)} isInvalid={!!errors.insurance_plan}>
                          <option>Select Plan Type</option>
                          {category.length > 0 && category.map((categoryItem, index) => {
                            return <option value={categoryItem.slug}>{categoryItem.title}</option>
                          })}
                        </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.insurance_plan_type}
                          </Form.Control.Feedback> 
                          </>
                      )} />
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12}>
                    <Form.Group controlId="validationFormik10">
                        
                    <Field name="insurance_plan" render={({ field, form }) => (
                        <>
                        <Form.Select className="form-select" aria-label="Default select example" name="insurance_plan" value={values.insurance_plan} onChange={handleCustomChange(field, form)} isInvalid={!!errors.insurance_plan}>
                          <option>Select Insurance Plan</option>
                          {planData.length > 0 && planData.map((planItem, index) => {
                          return <option value={planItem.s_slug}>{planItem.sub_category_title}</option>
                        })}
                        </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.insurance_plan}
                          </Form.Control.Feedback> 
                          </>
                      )} />
                    </Form.Group>
                  </Col>
                  <Col md={12} lg={12}>
                    <Link className="cutom-button" onClick={handleSubmit} type="submit">Submit <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
                  </Col>
                </Row>
              </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Modal.Body>
    </Modal >
  )
}

export default FreeQuoteModal