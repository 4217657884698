import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    planInfo: [],
    userinfo: {}
}

export const storeResultSlice = createSlice({
  name: 'result',
  initialState,
  reducers: {
    storeResult: (state, action) => {
      state.planInfo = action.payload
      },
    storeUserInfo: (state, action) => {
      state.userinfo = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const { storeResult, storeUserInfo } = storeResultSlice.actions

export default storeResultSlice.reducer