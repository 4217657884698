import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import atiIcon1 from '../assets/images/ati-icon-1.png'
import atiIcon2 from '../assets/images/ati-icon-2.png'
import atiIconRight from '../assets/images/ati-icon-right.png'
import ComparePricesAmong from './ComparePricesAmong';

function TopInsurers(props) {
  return (
    <div className='top-insurers common-padding pb-0' id='topInsurers'>
      <Container>
        <ComparePricesAmong />
        </Container>
        <Row className='life-health'>
          <Col lg={6} className="lefe-box">
            <div className="lefe-box-group">
              <div className='lefe-box-img'>
                <img src={props.mainCatInfo.history_left_icon} alt="" />
              </div>
              <div className='lefe-box-text'>
                <span>{props.mainCatInfo.history_left_first_text}</span>
                <h2>{props.mainCatInfo.history_left_number}</h2>
                <span>{props.mainCatInfo.history_left_last_text}</span>
              </div>
            </div>
          </Col>
          <Col lg={6} className="health-box">
            <div className="health-box-group">
              <div className='health-box-text'>
                <span>{props.mainCatInfo.history_right_first_text}</span>
                <h2>{props.mainCatInfo.history_right_number}</h2>
                <span>{props.mainCatInfo.history_right_last_text}</span>
              </div>
              <div className='health-box-img'>
                <img src={props.mainCatInfo.history_right_icon} alt="" />
              </div>
            </div>
            <div className="ati-right-center">
              <img src={atiIconRight} alt="" />
            </div>
          </Col>
        </Row>
      
    </div>
  )
}

export default TopInsurers