import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'

import Calendar from '../assets/images/calendar.svg'
function SimilarBlog(props) {
    return (
        <>
            <div className='similar-blog'>
                <h3>Similar Blog</h3>
                <div className="similar-blog-list">
                    <ul>
                        {props.similarItems.length > 0 && props.similarItems.map((similarItem, index) => { 
                            return <li>
                                <div className='similar-blog-main'>
                                    <Link to=""><img src={similarItem.main_img} alt="" /></Link>
                                    <div className="similar-blog-text">
                                        <div className='date-box'><span><img src={Calendar} alt="" /></span>{similarItem.blog_date}</div>
                                        <a href={"/articles-details/" + similarItem.slug}>{similarItem.main_title}</a>
                                    </div>
                                </div>
                            </li>
                        })}

                    </ul>
                </div>
            </div>
        </>
    )
}

export default SimilarBlog











