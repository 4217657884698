import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import wti1 from '../assets/images/wti-1.png'
import wti2 from '../assets/images/wti-2.png'
import wti3 from '../assets/images/wti-3.png'
import wti4 from '../assets/images/wti-4.png'
import wticentericon from '../assets/images/wti-center-icon.png'
import { Link } from 'react-router-dom';
import buttonArow from '../assets/images/right-arrow.png'
import { LIST_WHY_TRUST } from '../api/constant'
function WhyTrustInsurance() {
  const [whyTrustItems, setWhyTrustItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  useEffect(() => {
    const listWhyTrust = async () => {
      const res = await fetch(LIST_WHY_TRUST)
      const data = await res.json()
      if (data.status) {
        setWhyTrustItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    listWhyTrust();

  }, []);

  return (
    <>
      <div className='why-trust-insurance common-padding'>
        <Container>
          <Row>
            <Col>
              <div className='common-title'>
                <h2>Why Trust Insurance?</h2>
              </div>
            </Col>
          </Row>
          <div className='why-trust-insurance-group'>
            <Row>
              {whyTrustItems.length > 0 && whyTrustItems.map((whyTrustItem, index) => {
                return <Col sm={12} md={6} className={ `col-why-trust-insurance-box ${whyTrustItem.icon_class}`} key={whyTrustItem.id}>
                  <div className={`why-trust-insurance-box ${whyTrustItem.even_class}`}>
                    <div className='why-trust-insurance-box-img'>
                      <img src={whyTrustItem.icon_img} alt="{whyTrustItem.question}" />
                    </div>
                    <div className='why-trust-insurance-box-text'>
                      <h3>{whyTrustItem.title}</h3>
                      <div dangerouslySetInnerHTML={{ __html: whyTrustItem.description }} />
                    </div>
                  </div>
                </Col >
              })}
            </Row >
            <div className='why-trust-insurance-box-center-icon'>
              <img src={wticentericon} alt="" />
            </div>
          </div>
        </Container >
      </div >
    </>
  )
}

export default WhyTrustInsurance