import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
// import 'swiper/modules/navigation/navigation.min.css'
// import 'swiper/modules/effect-fade/effect-fade.min.css';
// import 'swiper/modules/autoplay/autoplay.min.css';
import { Autoplay } from "swiper";

import { LIST_ALL_COMPARE_COMPANY } from '../api/constant'
function ComparePricesAmong() {
  const [companyItems, setcompanyItems] = useState([])
  const [errMsg, setErrMsg] = useState(null)

  useEffect(() => {
    const listAllCompany = async () => {
      const res = await fetch(LIST_ALL_COMPARE_COMPANY)
      const data = await res.json()
      if (data.status) {
        setcompanyItems(data.data)
      } else {
        setErrMsg(data.message)
      }
    }
    listAllCompany();
  }, []);
  return (
    <>
      <Row className='mb-70 about-mb-0'>
        <Col lg={12} xl={3}>
          <div className="top-insurers-left">
            <span>Compare prices among</span>
            <h4>America's top insurers</h4>
          </div>
        </Col>
        <Col xs={12} xl={9}>
          <div className="top-insurers-right">
            <Swiper autoplay={{
              delay: 2500,
            }} modules={[Autoplay]} breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 0
              },
              576: {
                slidesPerView: 3,
                spaceBetween: 10
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 10
              },
              1199: {
                slidesPerView: 5,
                spaceBetween: 10,
                slidesOffsetBefore: 0,
              },
            }} spaceBetween={10} loop={true} slidesPerView={5} className="mySwiper ">
              {companyItems.length > 0 && companyItems.map((companyItem) => {
                return <SwiperSlide key={companyItem.id}>
                  <img src={companyItem.logo_img} alt="" />
                </SwiperSlide>
              })}
            </Swiper >
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ComparePricesAmong