import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import banefitList from '../assets/images/banefit-list.png'
import { Link } from 'react-router-dom'
// import banefit1 from '../assets/images/banifit-img-1.png'
// import banefit2 from '../assets/images/banifit-img-2.png'
// import banefit3 from '../assets/images/banifit-img-3.png'
// import banefit4 from '../assets/images/banifit-img-4.png'

import buttonArow from '../assets/images/button-arrow-hover.svg'
import buttonArowWhite from '../assets/images/button-arrow.svg'
import Stepper from './Stepper';
import { GET_SUBCATEGORY_INFO_BY_SLUG } from '../api/constant'
import FreeQuoteModal from '../component/FreeQuoteModal'
function Benefits(props) {
  const [modalShow, setModalShow] = React.useState(false);
  let subslug = props.subslug;
  let mainslug = props.mainslug;
  let setingData = props.setingData;
  const [subCategoryInfo, setSubCategoryInfo] = useState([])
  const [errMsg, setErrMsg] = useState(null)
  useEffect(() => {

    const getSubCategoryBySlug = async () => {
      const res = await fetch(GET_SUBCATEGORY_INFO_BY_SLUG + subslug)
      const data = await res.json()
      if (data.status) {
        setSubCategoryInfo(data.data)
        // Set the parent plan details function title.
        props.renderPlanTitle(data.data.title);
      } else {
        setErrMsg(data.message)
      }
    }


    getSubCategoryBySlug();

  }, []);
  const openFreeQuoteForm = () => {
    const linkGetFreeQuote = document.getElementById('getFreeQuote');
    if (linkGetFreeQuote) {
      linkGetFreeQuote.click();
    }
  };



  const getListFromHtml = (htmlString) => {
    // Create a new DOMParser instance
    let parser = new DOMParser();
    // Parse the HTML string into a document
    let doc = parser.parseFromString(htmlString, 'text/html');
    // Get all <li> elements
    return  doc.querySelectorAll('li');
  }

  const getBenefitsByList = (htmlString, eq) => {
    const liElements = getListFromHtml(htmlString);
    if(liElements.length > 0){
      return liElements[eq].innerHTML;
    }else{
      return null;
    }
  }

  const getRemainingBenefitsList = (htmlString) => {
    const liElements = getListFromHtml(htmlString);
    if(liElements.length > 2){
      

      var htmlString = Array.from(liElements)
      .slice(2)
      .map((li, index) => `<li>${li.innerHTML}</li>`)
      .join('');
      return "<ul>"+htmlString+"</ul>";
    }else{
      return null;
    }
  }



  
  return (
    <>
    <div className='benefits benefits-header'>
      <Row className='benefits-box'>
        <Col lg={4}>
          <div className='benefits-box-text'>
            <h2>Benefits of a {subCategoryInfo.title}</h2>
            <span></span>
            <ul>
              <li dangerouslySetInnerHTML={{ __html: getBenefitsByList(subCategoryInfo.benefits, 0) }} />
              <li dangerouslySetInnerHTML={{ __html: getBenefitsByList(subCategoryInfo.benefits, 1) }} />
            </ul>
          </div>
        </Col>
        <Col lg={8} >
          <div className='benefits-step-group'>
            <h4>Fill the few details for the {subCategoryInfo.title}</h4>
            <span className='common-grad benefits-step-span'></span>
            <Stepper subslug={subslug} />
          </div>
        </Col>
      </Row>
    </div>
    <div className='benefits  benefits-content'>
      <Row className='benefits-box'>
        <Col lg={8}>
          <div className='benefits-box-text br-tl'>
            {/* <div dangerouslySetInnerHTML={{ __html: subCategoryInfo.benefits }} /> */}
            <div dangerouslySetInnerHTML={{ __html: getRemainingBenefitsList(subCategoryInfo.benefits) }} /> 
            
            <div className='benefits-btn-group'>
            <Link className={`cutom-button mt-0 btn-apply-by-mail-${subslug}`} onClick={() => setModalShow(true)}>Apply by Mail <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link> 
            <Link className={`cutom-button mt-0 btn-meet-with-an-agent-${subslug}`} onClick={() => setModalShow(true)}>Meet with an Agent <img className='on-hover' src={buttonArow} alt="" /> <img className='defailt' src={buttonArowWhite} alt="" /></Link>
            </div>
          </div>
        </Col>
        <Col lg={4} >
          <Row>
            <Col md={12} lg={12}>
              <div className='our-mission-values-img-main'>
                <img className="our-mission-values-img" src={subCategoryInfo.plan_detail_image} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className='our-mission-values-img-main center-img'>
                <img className="our-mission-values-img" src={subCategoryInfo.plan_detail_image_2} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className='our-mission-values-img-main center-img'>
                <img className="our-mission-values-img" src={subCategoryInfo.plan_detail_image_3} alt="" />
              </div>
            </Col>
            <Col md={12} lg={12}>
              <div className='our-mission-values-img-main'>
                <img className="our-mission-values-img" src={subCategoryInfo.plan_detail_image_4} alt="" />
              </div>
            </Col>

            {/* 
            <Col md={12} lg={12}>
              <div className='our-mission-values-img-main'>
                <img className="our-mission-values-img" src={banefit1} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className='our-mission-values-img-main center-img'>
                <img className="our-mission-values-img" src={banefit2} alt="" />
              </div>
            </Col>
            <Col xs={6}>
              <div className='our-mission-values-img-main center-img'>
                <img className="our-mission-values-img" src={banefit3} alt="" />
              </div>
            </Col>
            <Col md={12} lg={12}>
              <div className='our-mission-values-img-main'>
                <img className="our-mission-values-img" src={banefit4} alt="" />
              </div>
            </Col> */}
          </Row>
        </Col>
      </Row>
    </div>
    <FreeQuoteModal
    show={modalShow}
    onHide={() => setModalShow(false)}
  />
  </>
  )
}

export default Benefits